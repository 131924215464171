body.active-modal {
  overflow-y: hidden;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block;
}

.overlay {
  background: rgba(0, 0, 0, 0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 5px 5px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.close-modal {
  position: absolute;
  bottom: -50px;
  right: 10px;
  padding: 5px 7px;
}

.modal path {
  fill: #ddd;
}
.modal path:hover {
  fill: #fff;
}

/* Modal Transition */
.modal-transition-enter {
  opacity: 0;
}

.modal-transition-enter-active {
  opacity: 1;
  transition: opacity 600ms, transform 600ms;
}

.modal-transition-exit {
  opacity: 1;
}

.modal-transition-exit-active {
  opacity: 0;
  transition: opacity 600ms, transform 600ms;
}
/* Modal Transition Panel */
.modal-transition-panel-enter {
  max-height: 0;
  opacity: 0;
}

.modal-transition-panel-enter-active {
  max-height: 1000px;
  opacity: 1;
  transition: max-height 1500ms, opacity 1500ms;
}

.modal-transition-panel-exit {
  max-height: 1000px;
  opacity: 1;
}

.modal-transition-panel-exit-active {
  max-height: 0;
  opacity: 0;
  transition: max-height 500ms, opacity 500ms;
}
