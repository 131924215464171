@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i,900,900i%7CRoboto:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Raleway:400,700,900");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://cdn.linearicons.com/free/1.0.0/icon-font.min.css");

body {
  overflow-x: hidden;
}

.col-lg-offset-right-1,
.col-md-offset-right-1 {
  margin-right: 8.33333333%;
}
ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
}
a:focus,
a:hover {
  text-decoration: none !important;
  outline: none;
}
.row.m0 {
  margin: 0px;
}
.p0 {
  padding: 0px;
}
body {
  line-height: 24px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #888890;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  padding: 0px;
}
#success {
  display: none;
}
#error {
  display: none;
}
.display_table {
  display: table;
  width: 100%;
}
.display_table_row {
  display: table-row;
}
.display_table_cell {
  display: table-cell;
  float: none !important;
}
iframe {
  border: none;
}
i:before {
  margin-left: 0px !important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #6ed2e6;
}
::-moz-selection {
  background-color: #6ed2e6;
  color: #fff;
}
::selection {
  background-color: #6ed2e6;
  color: #fff;
}
-webkit-::-moz-selection {
  background-color: #6ed2e6;
  color: #fff;
}
-webkit-::selection {
  background-color: #6ed2e6;
  color: #fff;
}
::-moz-selection {
  background-color: #6ed2e6;
  color: #fff;
}
.main_title {
  text-align: center;
  display: block;
}
.main_title h5 {
  font-size: 16px;
  color: #888890;
  font-family: "Roboto", serif;
  padding-bottom: 12px;
}
.main_title h2 {
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
  position: relative;
  padding-bottom: 25px;
}
.main_title h2:before {
  content: "";
  width: 102px;
  height: 1px;
  background: #f07f84;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0px;
}
.main_title p {
  color: #888890;
  max-width: 695px;
  margin: 25px auto 9px;
}
.main_w_title {
  text-align: center;
  display: block;
}
.main_w_title h5 {
  font-size: 16px;
  color: #fff;
  font-family: "Roboto", serif;
  padding-bottom: 12px;
  font-style: italic;
}
.main_w_title h2 {
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  position: relative;
  padding-bottom: 25px;
}
.main_w_title h2:before {
  content: "";
  width: 102px;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0px;
}
.main_w_title p {
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  font-weight: 400;
  max-width: 830px;
  margin: auto;
  padding-top: 35px;
}
.main_w_title p + p {
  padding-top: 25px;
}
.main_w_r_title {
  text-align: center;
  display: block;
}
.main_w_r_title h5 {
  font-size: 16px;
  color: #fff;
  font-family: "Roboto", serif;
  padding-bottom: 12px;
  font-style: italic;
}
.main_w_r_title h2 {
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  position: relative;
  padding-bottom: 25px;
}
.main_w_r_title h2:before {
  content: "";
  width: 102px;
  height: 1px;
  background: #f07f84;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0px;
}
.main_w_r_title p {
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  font-weight: 400;
  max-width: 830px;
  margin: auto;
  padding-top: 35px;
}
.main_w_r_title p + p {
  padding-top: 25px;
}
.menu_title {
  text-align: center;
}
.menu_title h2 {
  font-size: 20px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  padding-bottom: 22px;
}
.menu_title p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #888890;
  line-height: 24px;
}
.sec_title {
  color: #fff;
}
.sec_title h5 {
  font: 400 16px/26px "Roboto", serif;
  text-transform: capitalize;
}
.sec_title h2 {
  font: 500 36px/40px "Roboto", sans-serif;
  position: relative;
  padding-bottom: 18px;
}
.sec_title h2:before {
  content: "";
  width: 100px;
  height: 1px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
}
.sec_title p {
  font: 400 14px/24px "Roboto", sans-serif;
  padding-top: 27px;
}
.sec_title3 {
  color: #1a1a26;
}
.sec_title3 h2:before {
  background: #6ed2e6;
}
.sec_b_title h5 {
  font: 400 16px/26px "Roboto", serif;
  text-transform: capitalize;
  color: #888890;
  padding-bottom: 5px;
}
.sec_b_title h2 {
  font: 500 36px/46px "Roboto", sans-serif;
  position: relative;
  padding-bottom: 15px;
  color: #1a1a26;
}
.sec_b_title h2:before {
  content: "";
  width: 100px;
  height: 1px;
  background: #f07f84;
  position: absolute;
  bottom: 0;
  left: 0;
}
.sec_b_title p {
  font: 400 14px/24px "Roboto", sans-serif;
  padding-top: 27px;
  color: #888890;
}
.full_sec_title {
  text-align: center;
  display: block;
}
.full_sec_title h5 {
  font-size: 16px;
  color: #888890;
  font-family: "Roboto", serif;
  padding-bottom: 12px;
}
.full_sec_title h2 {
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
  position: relative;
}
.full_sec_title p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  color: #888890;
  max-width: 590px;
  margin: auto;
  padding-top: 15px;
}
.full_left_sec_title {
  text-align: left;
  display: block;
}
.full_left_sec_title h5 {
  font-size: 16px;
  color: #888890;
  font-family: "Roboto", serif;
  padding-bottom: 12px;
}
.full_left_sec_title h2 {
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
  position: relative;
}
.full_left_sec_title p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  color: #888890;
  margin: auto;
  padding-top: 15px;
}
.text_s_t {
  margin-bottom: 30px;
}
.text_s_t h3 {
  font-size: 24px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 15px;
}
.text_s_t h3:before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 50px;
  height: 2px;
  background: #6ed2e6;
}
.get_c_title {
  text-align: center;
  color: #fff;
  position: relative;
  padding-bottom: 25px;
}
.get_c_title:before {
  content: "";
  width: 100px;
  height: 1px;
  background: #6ed2e6;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.get_c_title h2 {
  font-size: 32px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  padding-bottom: 10px;
}
.get_c_title p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.breadcumd_title {
  text-align: center;
}
.breadcumd_title h2 {
  font-size: 60px;
  line-height: 60px;
  color: #1a1a26;
  text-transform: capitalize;
  padding-bottom: 20px;
}
.breadcumd_title p {
  color: #888890;
}
.full_page_title h5 {
  font-style: italic;
  color: #1a1a26;
}
.full_page_title h2 {
  text-transform: uppercase;
}
.full_page_title h2:before {
  background: #6ed2e6;
}
.full_page_title.title_white h2,
.full_page_title.title_white h5,
.full_page_title.title_white p {
  color: #fff;
}
.mar75 {
  margin-top: 75px;
}
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
.loader-container {
  position: fixed;
  float: left;
  top: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background: #353030;
  z-index: 999999;
}
.loader-container .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: auto;
}
#mask {
  width: 600px;
  height: 232px;
  line-height: 76px;
  margin: 0 auto;
  text-align: center;
  font-size: 25px;
}
#mask svg {
  width: 64px;
  height: 76px;
}
#mask svg .preloader-path {
  stroke-linejoin: round;
  fill: none;
  stroke: #6ed2e6;
  stroke-width: 1;
  stroke-dasharray: 70 32;
  -webkit-animation: preloader 1s infinite ease;
  animation: preloader 1s infinite ease;
}
@-webkit-keyframes preloader {
  0% {
    stroke-width: 0;
    stroke-dashoffset: 0;
  }
  to {
    stroke-width: 1;
    stroke-dashoffset: 102px;
  }
}
@keyframes preloader {
  0% {
    stroke-width: 0;
    stroke-dashoffset: 0;
  }
  to {
    stroke-width: 1;
    stroke-dashoffset: 102px;
  }
}
.h_pad {
  padding-left: 60px;
  padding-right: 60px;
}
.main_header_area.split_fullpage {
  border: 0px;
}
.main_header_area.split_fullpage .pull-left .h_contact_bar:before,
.main_header_area.split_fullpage .pull-right .header_socila:before {
  display: none;
}
.main_header_area.split_fullpage .pull-right i {
  padding-right: 0px;
}
.main_header_area {
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 10;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.main_header_area .pull-left i {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  line-height: 99px;
  margin-right: 18px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}
.main_header_area .pull-left img {
  margin-right: 50px;
  padding-top: 36px;
}
.main_header_area .pull-left .h_contact_bar {
  font-size: 16px;
  line-height: 99px;
  padding-left: 44px;
  color: #fff;
  position: relative;
  vertical-align: top;
  display: inline-block;
  height: 100%;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.main_header_area .pull-left .h_contact_bar i {
  font-size: 16px;
  margin-right: 10px;
}
.main_header_area .pull-left .h_contact_bar span {
  font-weight: 500;
}
.main_header_area .pull-left .h_contact_bar:before {
  content: "";
  height: 50px;
  width: 1px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 50%;
  left: 0px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.main_header_area .pull-left .h_contact_bar:hover {
  color: #6ed2e6;
}
.main_header_area .pull-right i {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  line-height: 99px;
  padding-right: 0px;
  cursor: pointer;
}
.main_header_area .pull-right .quote_btn {
  overflow: hidden;
  position: relative;
  top: 15px;
  margin-left: 50px;
}
.main_header_area .pull-right .header_socila {
  display: inline-block;
  vertical-align: top;
  padding-right: 95px;
  position: relative;
}
.main_header_area .pull-right .header_socila:before {
  content: "";
  width: 1px;
  height: 50px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  right: 48px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.main_header_area .pull-right .header_socila li {
  display: inline-block;
  vertical-align: top;
}
.main_header_area .pull-right .header_socila li a {
  font-size: 18px;
  vertical-align: top;
  display: inline-block;
}
.main_header_area .pull-right .header_socila li a i {
  font-size: 18px;
  padding-right: 20px;
  vertical-align: top;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.main_header_area .pull-right .header_socila li a:hover i {
  color: #6ed2e6;
}
.main_header_area .pull-right .header_socila li:last-child a i {
  padding-right: 0px;
}
.main_header_area.business_menu {
  background: #fff;
  border-bottom: none;
}
.main_header_area.business_menu .navbar-default {
  margin-bottom: 0px;
  border-radius: 0px;
  background: transparent;
  border: none;
  text-align: right;
  margin-right: 0px;
}
.main_header_area.business_menu .navbar-default .navbar-header .navbar-brand {
  display: inline-block;
  padding: 0px 0px 0px 15px;
  line-height: 93px;
}
.main_header_area.business_menu
  .navbar-default
  .navbar-header
  .navbar-brand
  img {
  max-width: 100%;
  display: inline-block;
  padding: 5px;
}
.main_header_area.business_menu .navbar-default .nav.navbar-nav.navbar-right {
  margin-left: 40px;
  margin-right: 0px;
}
.main_header_area.business_menu
  .navbar-default
  .nav.navbar-nav.navbar-right
  li {
  margin-right: 28px;
}
.main_header_area.business_menu
  .navbar-default
  .nav.navbar-nav.navbar-right
  li
  a {
  font-size: 24px;
  color: #1a1a26;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.main_header_area.business_menu
  .navbar-default
  .nav.navbar-nav.navbar-right
  li:last-child {
  margin-right: 0px;
}
.main_header_area.business_menu
  .navbar-default
  .nav.navbar-nav.navbar-right
  li:hover
  a {
  color: #888890;
}
.main_header_area.business_menu
  .navbar-default
  .nav.navbar-nav.navbar-right
  li.shop_cart
  a
  i {
  position: relative;
}
.main_header_area.business_menu
  .navbar-default
  .nav.navbar-nav.navbar-right
  li.shop_cart
  a
  i:after {
  content: "2";
  background: #6ed2e6;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  position: absolute;
  top: -7px;
  right: -11px;
  font-size: 11px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 20px;
}
.main_header_area.business_menu.affix {
  background: #fff;
}
.main_header_area.business_menu.transparent_menu {
  background: #000;
  min-height: 128px;
}
.main_header_area.business_menu.transparent_menu
  .navbar-default
  .nav.navbar-nav
  > li
  > a {
  color: #fff;
}
.main_header_area.business_menu.transparent_menu
  .navbar-default
  .nav.navbar-nav
  > li.active
  > a,
.main_header_area.business_menu.transparent_menu
  .navbar-default
  .nav.navbar-nav
  > li:hover
  > a {
  color: #6ed2e6;
}
.main_header_area.business_menu.transparent_menu
  .navbar-default
  .nav.navbar-nav.navbar-right
  li {
  margin-right: 0px;
}
.main_header_area.business_menu.transparent_menu
  .navbar-default
  .nav.navbar-nav.navbar-right
  li
  + li {
  margin-left: 35px;
  vertical-align: top;
}
@media (min-width: 767px) {
  .main_header_area.business_menu.transparent_menu
    .navbar-default
    .nav.navbar-nav.navbar-right
    li:last-child {
    display: none;
  }
}
.main_header_area.business_menu.transparent_menu.affix {
  background: #000;
}
.main_header_area .nav.navbar-nav {
  float: none;
  display: inline-block;
  text-align: right;
  margin-top: 20px;
}
.main_header_area .nav.navbar-nav > li {
  display: inline-block;
  float: none;
  margin-right: 35px;
}
.main_header_area .nav.navbar-nav > li a {
  background: transparent;
  padding: 0px;
  line-height: 100px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  color: #1a1a26;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.main_header_area .nav.navbar-nav > li:last-child {
  margin-right: 0px;
}
.main_header_area .nav.navbar-nav > li.active a,
.main_header_area .nav.navbar-nav > li:hover a {
  color: #888890;
  background: transparent;
}
@media (min-width: 768px) {
  .main_header_area .nav.navbar-nav > li.dropdown .sub-menu {
    min-width: 210px;
    padding: 10px 30px 6px;
    position: absolute;
    background: #fff;
    display: block;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    left: 0;
    border-radius: 5px;
    border: 0px;
    top: 60%;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    max-height: 550px;
  }
  .main_header_area
    .nav.navbar-nav
    > li.dropdown
    .sub-menu.mCustomScrollbar
    .mCSB_container {
    margin-right: 0px;
  }
  .main_header_area
    .nav.navbar-nav
    > li.dropdown
    .sub-menu.mCustomScrollbar
    .mCSB_scrollTools {
    width: 0px;
  }
  .main_header_area .nav.navbar-nav > li.dropdown .sub-menu li {
    display: block;
  }
  .main_header_area .nav.navbar-nav > li.dropdown .sub-menu li a {
    font: 400 14px/40px "Roboto", sans-serif;
    color: #888890;
    white-space: nowrap;
    text-transform: capitalize;
    display: block;
    padding-left: 0px;
  }
  .main_header_area .nav.navbar-nav > li.dropdown .sub-menu li a:focus,
  .main_header_area .nav.navbar-nav > li.dropdown .sub-menu li a:hover {
    color: #6ed2e6;
    background-color: transparent;
  }
  .main_header_area .nav.navbar-nav > li.dropdown .sub-menu li.active a {
    color: #6ed2e6;
  }
}
@media (min-width: 768px) {
  .main_header_area .nav.navbar-nav > li:focus.dropdown .sub-menu,
  .main_header_area .nav.navbar-nav > li:hover.dropdown .sub-menu {
    top: 100%;
    opacity: 1;
    visibility: visible;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .main_header_area
    .nav.navbar-nav
    > li:focus.dropdown
    .sub-menu
    li:hover
    .dropdown-menu,
  .main_header_area
    .nav.navbar-nav
    > li:hover.dropdown
    .sub-menu
    li:hover
    .dropdown-menu {
    top: 0px;
    visibility: visible;
    opacity: 1;
  }
}
.main_header_area.touch_menu {
  background: #353030;
}
.main_header_area.touch_menu.black_menu .menu .nav.navbar-nav > li > a {
  color: #1a1a26;
}
.main_header_area.touch_menu.black_menu .menu .nav.navbar-nav > li.active > a {
  color: #6ed2e6;
}
.main_header_area.touch_menu.black_menu .menu .nav.navbar-nav > li:hover > a {
  color: #6ed2e6;
}
.main_header_area.touch_menu.black_menu .right_m:before {
  background: rgba(0, 0, 0, 0.5);
}
.main_header_area.touch_menu.black_menu .right_m li a {
  color: #1a1a26;
}
.main_header_area.touch_menu.black_menu .right_m li a:hover {
  color: #6ed2e6;
}
.main_header_area.touch_menu.black_menu.affix {
  background: #fff;
}
.main_header_area.touch_menu .menu .nav.navbar-nav {
  text-align: right;
  padding-right: 50px;
}
.main_header_area.touch_menu .menu .nav.navbar-nav > li.active > a {
  color: #6ed2e6;
}
.main_header_area.touch_menu .menu .nav.navbar-nav > li > a {
  color: #fff;
}
.main_header_area.touch_menu .menu .nav.navbar-nav > li:hover > a {
  color: #6ed2e6;
}
.main_header_area.touch_menu .right_m {
  padding-left: 50px;
  position: relative;
  margin-top: 17px;
}
.main_header_area.touch_menu .right_m:before {
  content: "";
  height: 50px;
  width: 1px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 50%;
  left: 0px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.main_header_area.touch_menu .right_m .phone_number a {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.main_header_area.touch_menu .right_m .phone_number a span {
  font-weight: 500;
}
.main_header_area.touch_menu .right_m .phone_number a i {
  padding-right: 10px;
}
.main_header_area.touch_menu .right_m li {
  margin-right: 0px;
}
.main_header_area.touch_menu .right_m li + li {
  margin-left: 35px;
  vertical-align: top;
}
@media (min-width: 767px) {
  .main_header_area.touch_menu .right_m li:last-child {
    display: none;
  }
}
.main_header_area.full_black_menu {
  background: #fff;
  top: auto;
}
.main_header_area.full_black_menu .menu .nav.navbar-nav > li > a {
  color: #1a1a26;
}
.main_header_area.full_black_menu .menu .nav.navbar-nav > li.active > a,
.main_header_area.full_black_menu .menu .nav.navbar-nav > li:hover > a {
  color: #888890;
}
.main_header_area.full_black_menu .right_side.right_m ul li a {
  color: #1a1a26;
}
.main_header_area.full_black_menu .right_side.right_m ul li:hover a {
  color: #888890;
}
.main_header_area.full_black_menu.affix {
  background: #fff;
}
.main_header_area.fullpage_menu {
  border-bottom: none;
}
.main_header_area.fullpage_menu .pull-right i {
  padding-right: 0px;
  padding-left: 98px;
  position: relative;
}
.main_header_area.fullpage_menu .pull-right i:after {
  content: "";
  height: 50px;
  width: 1px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: -12px;
  left: 38%;
}
.main_header_area.affix {
  position: fixed;
  width: 100%;
  background: #353030;
  border-bottom: none;
  top: 0px;
}
.right_m li {
  margin-right: 28px;
}
.right_m li a {
  font-size: 24px;
  color: #1a1a26;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  line-height: 100px;
  color: #fff;
}
.right_m li:last-child {
  margin-right: 0px;
}
.right_m li:hover a {
  color: #888890;
}
.right_m li.shop_cart a i {
  position: relative;
}
.right_m li.shop_cart a i:after {
  content: "2";
  background: #6ed2e6;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  position: absolute;
  top: -7px;
  right: -11px;
  font-size: 11px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 20px;
}
.offcanvas_menu {
  position: fixed;
  left: -100%;
  min-height: 100vh;
  top: 0px;
  background: #fff;
  z-index: 12;
  width: 0;
  max-height: 100vh;
  padding: 55px 100px 80px 100px;
  overflow: scroll;
  -webkit-transition: all 600ms linear;
  -o-transition: all 600ms linear;
  transition: all 600ms linear;
}
.offcanvas_menu .closer_icon {
  text-align: right;
  margin-bottom: 65px;
  font-size: 20px;
  color: #3a3a45;
  cursor: pointer;
  margin-right: -40px;
}
.offcanvas_menu .off_menu {
  margin-top: 65px;
  margin-bottom: 65px;
  width: 0;
  opacity: 0;
  -webkit-transform: translateY(3rem);
  -ms-transform: translateY(3rem);
  transform: translateY(3rem);
  -webkit-transition: opacity 1.2s 1s ease, color 0.8s ease,
    -webkit-transform 1.2s 1s ease;
  transition: opacity 1.2s 1s ease, color 0.8s ease,
    -webkit-transform 1.2s 1s ease;
  -o-transition: transform 1.2s 1s ease, opacity 1.2s 1s ease, color 0.8s ease;
  transition: transform 1.2s 1s ease, opacity 1.2s 1s ease, color 0.8s ease;
  transition: transform 1.2s 1s ease, opacity 1.2s 1s ease, color 0.8s ease,
    -webkit-transform 1.2s 1s ease;
}
.offcanvas_menu .off_menu .nav {
  max-height: 230px;
  overflow-y: scroll;
}
.offcanvas_menu .off_menu .nav li a {
  background: transparent;
  text-transform: uppercase;
  font-size: 24px;
  color: #888890;
  font-weight: 600;
  padding: 12px 0px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  display: block;
}
.offcanvas_menu .off_menu .nav li.active a {
  color: #1a1a26;
}
.offcanvas_menu .off_menu .nav li:first-child {
  margin-top: 0px;
}
.offcanvas_menu .off_menu .nav li:hover a {
  color: #1a1a26;
}
.offcanvas_menu .off_menu .nav li.submenu ul {
  display: none;
}
.offcanvas_menu .off_menu .nav li.submenu ul li {
  margin-top: 0px;
}
.offcanvas_menu .off_menu .nav li.submenu ul li a {
  padding: 10px 10px;
  border-bottom: 1px solid #ededed;
  display: block;
  font-size: 18px;
  color: #888890;
  text-transform: capitalize;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  font-weight: 500;
}
.offcanvas_menu .off_menu .nav li.submenu ul li.active a {
  color: #1a1a26;
}
.offcanvas_menu .off_menu .nav li.submenu ul li:last-child a {
  border-bottom: 0px;
}
.offcanvas_menu .off_menu .nav li.submenu ul li:hover a {
  color: #1a1a26;
}
.offcanvas_menu .off_menu .mCustomScrollbar .mCSB_scrollTools {
  width: 5px;
  border-radius: 0px;
}
.offcanvas_menu
  .off_menu
  .mCustomScrollbar
  .mCSB_scrollTools
  .mCSB_draggerContainer
  .mCSB_dragger {
  border-radius: 0px;
}
.offcanvas_menu
  .off_menu
  .mCustomScrollbar
  .mCSB_scrollTools
  .mCSB_draggerContainer
  .mCSB_dragger
  .mCSB_dragger_bar {
  background: #6ed2e6;
  border-radius: 0px;
}
.offcanvas_menu
  .off_menu
  .mCustomScrollbar
  .mCSB_scrollTools
  .mCSB_draggerContainer
  .mCSB_draggerRail {
  background: #fff;
  border-radius: 0px;
}
.offcanvas_menu .curency {
  text-align: left;
  max-width: 280px;
  margin: auto;
  padding-bottom: 13px;
  opacity: 0;
  -webkit-transform: translateY(3rem);
  -ms-transform: translateY(3rem);
  transform: translateY(3rem);
  -webkit-transition: opacity 1.3s 1.1s ease, color 0.9s ease,
    -webkit-transform 1.3s 1.1s ease;
  transition: opacity 1.3s 1.1s ease, color 0.9s ease,
    -webkit-transform 1.3s 1.1s ease;
  -o-transition: transform 1.3s 1.1s ease, opacity 1.3s 1.1s ease,
    color 0.9s ease;
  transition: transform 1.3s 1.1s ease, opacity 1.3s 1.1s ease, color 0.9s ease;
  transition: transform 1.3s 1.1s ease, opacity 1.3s 1.1s ease, color 0.9s ease,
    -webkit-transform 1.3s 1.1s ease;
}
.offcanvas_menu .curency h4 {
  display: inline-block;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.offcanvas_menu .curency a {
  padding-left: 27px;
  font-size: 14px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.offcanvas_menu .curency a.active,
.offcanvas_menu .curency a:hover {
  color: #1a1a26;
}
.offcanvas_menu .curency.language h4 {
  padding-right: 7px;
}
.offcanvas_menu .of_social {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  opacity: 0;
  -webkit-transform: translateY(3rem);
  -ms-transform: translateY(3rem);
  transform: translateY(3rem);
  -webkit-transition: opacity 1.4s 1.2s ease, color 1s ease,
    -webkit-transform 1.4s 1.2s ease;
  transition: opacity 1.4s 1.2s ease, color 1s ease,
    -webkit-transform 1.4s 1.2s ease;
  -o-transition: transform 1.4s 1.2s ease, opacity 1.4s 1.2s ease, color 1s ease;
  transition: transform 1.4s 1.2s ease, opacity 1.4s 1.2s ease, color 1s ease;
  transition: transform 1.4s 1.2s ease, opacity 1.4s 1.2s ease, color 1s ease,
    -webkit-transform 1.4s 1.2s ease;
}
.offcanvas_menu .of_social li {
  display: inline-block;
  margin-right: 25px;
}
.offcanvas_menu .of_social li a {
  font-size: 16px;
  color: #888890;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.offcanvas_menu .of_social li:last-child {
  margin-right: 0px;
}
.offcanvas_menu .of_social li:hover a {
  color: #1a1a26;
}
.offcanvas_menu .copy_right_menu {
  text-align: center;
  opacity: 0;
  -webkit-transform: translateY(3rem);
  -ms-transform: translateY(3rem);
  transform: translateY(3rem);
  -webkit-transition: opacity 1.5s 1.2s ease, color 01s ease,
    -webkit-transform 1.5s 1.2s ease;
  transition: opacity 1.5s 1.2s ease, color 01s ease,
    -webkit-transform 1.5s 1.2s ease;
  -o-transition: transform 1.5s 1.2s ease, opacity 1.5s 1.2s ease,
    color 01s ease;
  transition: transform 1.5s 1.2s ease, opacity 1.5s 1.2s ease, color 01s ease;
  transition: transform 1.5s 1.2s ease, opacity 1.5s 1.2s ease, color 01s ease,
    -webkit-transform 1.5s 1.2s ease;
}
.offcanvas_menu .copy_right_menu p {
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 24px;
  max-width: 240px;
  margin: auto;
}
.offcanvas_menu .copy_right_menu p a {
  color: #888890;
}
.offcanvas_menu .copy_right_menu p a:hover {
  color: #1a1a26;
}
.offcanvas_menu.open {
  width: 600px;
  left: 0px;
}
.offcanvas_menu.open .off_menu {
  width: 100%;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.offcanvas_menu.open .menu_title {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.offcanvas_menu.open .curency {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.offcanvas_menu.open .of_social {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.offcanvas_menu.open .copy_right_menu {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.offcanvas_menu .menu_title {
  opacity: 0;
  -webkit-transform: translateY(3rem);
  -ms-transform: translateY(3rem);
  transform: translateY(3rem);
  -webkit-transition: opacity 1s 0.8s ease, color 0.6s ease,
    -webkit-transform 1s 0.8s ease;
  transition: opacity 1s 0.8s ease, color 0.6s ease,
    -webkit-transform 1s 0.8s ease;
  -o-transition: transform 1s 0.8s ease, opacity 1s 0.8s ease, color 0.6s ease;
  transition: transform 1s 0.8s ease, opacity 1s 0.8s ease, color 0.6s ease;
  transition: transform 1s 0.8s ease, opacity 1s 0.8s ease, color 0.6s ease,
    -webkit-transform 1s 0.8s ease;
}
.offcanvas_menu.right_direction {
  left: auto;
  right: -100%;
}
.offcanvas_menu.right_direction.open {
  right: 0px;
  left: auto;
}
.full_sec_menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 0px;
}
.full_sec_menu .left_side .logo {
  display: inline-block;
  line-height: 90px;
  padding: 7px;
}
.full_sec_menu .right_side ul li {
  display: inline-block;
}
.full_sec_menu .right_side ul li:hover a {
  color: #6ed2e6;
}
.main_header_area .menu {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.main_header_area .menu .navbar-nav {
  width: 100%;
  text-align: center;
}
.main_header_area .menu .navbar-nav > li {
  float: none;
  display: inline-block;
  padding: 0px;
  margin-right: 0px;
}
.main_header_area .menu .navbar-nav > li a {
  font: 600 14px/100px "Roboto", sans-serif;
  color: #fff;
  text-transform: uppercase;
  padding: 0px;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.main_header_area .menu .navbar-nav > li + li {
  margin-left: 36px;
}
.main_header_area .menu .navbar-nav > li.active a,
.main_header_area .menu .navbar-nav > li:hover a {
  background: transparent;
  color: #6ed2e6;
}
.promo {
  color: #fff;
  font-family: "Roboto", sans-serif;
  padding: 190px 42px 0px;
  z-index: 1;
  position: relative;
}
.promo h2 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
}
.promo .purchase_btn {
  padding: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 40px;
  padding: 0px 20px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  margin-top: 34px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background: #6ed2e6;
  z-index: 1;
}
.promo .purchase_btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -moz-linear-gradient(180deg, #a1c4fd 0%, #c2e9fb 100%);
  background-image: -webkit-linear-gradient(180deg, #a1c4fd 0%, #c2e9fb 100%);
  background-image: -ms-linear-gradient(180deg, #a1c4fd 0%, #c2e9fb 100%);
  z-index: -1;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.promo .purchase_btn:hover:before {
  opacity: 0;
}
.show .main_header_area {
  top: 80px;
}
.show .main_header_area.business_menu.cont_menu {
  top: 0px;
}
.show .menu_top {
  top: 0;
}
.main_header_area.business_menu.cont_menu {
  border-bottom: 1px solid #ececec;
  position: relative;
}
.main_header_area.business_menu.cont_menu.affix {
  position: fixed;
  background: #fff;
}
.main_header_area.business_menu.cont_menu.affix + .row,
.main_header_area.business_menu.cont_menu.affix + div,
.main_header_area.business_menu.cont_menu.affix + section {
  margin-top: 101px;
}
.mobile_menu {
  border: none;
  background: #fff;
}
.mobile_menu .navbar.navbar-default {
  border-radius: 0px;
  background: transparent;
  border: none;
  margin: 0px;
}
.mobile_menu .navbar.navbar-default .navbar-header .navbar-brand {
  line-height: 94px;
  padding: 0px;
  display: inline-block;
}
.mobile_menu .navbar.navbar-default .navbar-header .navbar-brand img {
  display: inline-block;
}
.mobile_menu .navbar.navbar-default .nav.navbar-nav.navbar-right li {
  margin-right: 25px;
}
.mobile_menu .navbar.navbar-default .nav.navbar-nav.navbar-right li a {
  background: transparent;
  padding: 0px;
  line-height: 100px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #1a1a26;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  font-size: 24px;
}
.mobile_menu .navbar.navbar-default .nav.navbar-nav.navbar-right li a:hover {
  color: #6ed2e6;
}
.mobile_menu .navbar.navbar-default .nav.navbar-nav.navbar-right li:last-child {
  margin-right: 0px;
}
.mobile_menu.white_m_menu {
  background: transparent;
}
.mobile_menu.white_m_menu
  .navbar.navbar-default
  .nav.navbar-nav.navbar-right
  li
  a {
  color: #fff;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  font-size: 20px;
}
.mobile_menu.white_m_menu
  .navbar.navbar-default
  .nav.navbar-nav.navbar-right
  li
  a:hover {
  color: #6ed2e6;
}
.mobile_menu.white_m_menu.affix {
  background: #1a1a26;
}
.mobile_menu.affix {
  background: #fff;
}
.ab_menu_gap + div,
.ab_menu_gap + footer,
.ab_menu_gap + row,
.ab_menu_gap + section {
  margin-top: 100px;
}
.menu-br {
  border-bottom: 1px solid #dadedf;
}
.header_details_area {
  overflow: hidden;
  clear: both;
  background: #f8f8f8;
}
.header_details_area .pull-left h4 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #888890;
  line-height: 50px;
  font-weight: normal;
}
.header_details_area .pull-right ul li {
  display: inline-block;
  margin-right: 15px;
}
.header_details_area .pull-right ul li a {
  line-height: 50px;
  display: inline-block;
  font-size: 14px;
  color: #888890;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.header_details_area .pull-right ul li:last-child {
  margin-right: 0px;
}
.header_details_area .pull-right ul li:hover a {
  color: #1a1a26;
}
.menu_transparent {
  background: transparent;
}
.l_btn {
  height: 60px;
  width: 180px;
  border: 1px solid #fff;
  display: inline-block;
  text-align: center;
  line-height: 60px;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.l_btn:focus,
.l_btn:hover {
  background: #6ed2e6;
  border-color: #6ed2e6;
  color: #fff;
}
.c_btn {
  height: 60px;
  width: 180px;
  display: inline-block;
  background: #6ed2e6;
  border: 1px solid #6ed2e6;
  text-align: center;
  line-height: 60px;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  padding: 0px;
}
.c_btn:focus,
.c_btn:hover {
  background: transparent;
  border-color: #fff;
  color: #fff;
}
.quote_btn {
  background: #f07f84;
  width: 180px;
  height: 50px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  line-height: 50px;
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.quote_btn:focus,
.quote_btn:hover {
  background: #6ed2e6;
  color: #fff;
}
.p_btn {
  background: #6ed2e6;
  width: 180px;
  height: 50px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  line-height: 50px;
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.p_btn:focus,
.p_btn:hover {
  background: #f07f84;
  color: #fff;
}
.blog_r_btn {
  width: 160px;
  height: 40px;
  border: 1px solid #ececec;
  display: block;
  margin: auto;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #1a1a26;
  text-transform: uppercase;
  line-height: 40px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.blog_r_btn:focus,
.blog_r_btn:hover {
  background: #6ed2e6;
  border: 1px solid #6ed2e6;
  color: #fff;
}
.submit_dark {
  width: 210px;
  height: 60px;
  padding: 0px;
  border-radius: 0px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: #1e1e1f;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  line-height: 60px;
}
.submit_dark:focus,
.submit_dark:hover {
  background: #6ed2e6;
  color: #fff;
}
.submit_blue {
  width: 210px;
  height: 60px;
  padding: 0px;
  border-radius: 0px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: #6ed2e6;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.submit_blue:focus,
.submit_blue:hover {
  background: #1e1e1f;
  color: #fff;
}
.check_btn {
  font-size: 14px;
  line-height: 60px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  background: #1a1a26;
  display: inline-block;
  width: 210px;
  text-align: center;
  padding: 0px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.check_btn:focus,
.check_btn:hover {
  background: #6ed2e6;
  color: #fff;
}
.pricing_btn {
  padding: 0px;
  height: 50px;
  border-radius: 0px;
  line-height: 50px;
}
.pricing_btn:hover {
  background: #1a1a26;
}
.read_btn {
  border-radius: 0px;
  width: 140px;
  height: 50px;
  background: #fff;
  color: #1a1a26;
  line-height: 52px;
  border: 0px;
}
.read_btn:hover {
  background: #6ed2e6;
  color: #fff;
}
.read_m_b {
  width: 120px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  color: #888890;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  background: #fff;
  -webkit-box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.2);
}
.read_m_b:hover {
  color: #888890;
}
.main_slider_area {
  position: relative;
}
.main_slider_area .rev_slider {
  z-index: 0 !important;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .slotholder
  tp-bgimg.defaultimg {
  z-index: 3 !important;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .slider_text_box
  .button
  .c_btn {
  margin-left: 15px;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .slider_text_box
  .first_text {
  color: #fff;
  font-family: "Roboto", serif;
  font-style: italic;
  font-weight: bold;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .slider_text_box
  .secand_text {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .slider_text_box
  .third_text {
  color: #fff;
  text-align: center;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .slider_text_box
  .third_text
  h2 {
  text-align: center;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .slider_text_box
  .third_text
  p {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 24px;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .slider_text_box
  .digital_text {
  text-align: center !important;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .slider_text_box
  .multi_text {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
}
.main_slider_area .rev_slider .tp-revslider-mainul li .slider_text_box .port_1 {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.main_slider_area .rev_slider .tp-revslider-mainul li .slider_text_box .port_2 {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .slider_text_box
  .port_3
  .l_btn {
  width: 140px;
  height: 50px;
  line-height: 50px;
}
.main_slider_area .rev_slider .tp-revslider-mainul li .bg-box {
  background: #fff;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .lucian_text
  .lucian_details
  p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 24px;
}
.main_slider_area .rev_slider .tp-revslider-mainul li .infor_text ul li {
  margin-bottom: 10px;
}
.main_slider_area .rev_slider .tp-revslider-mainul li .infor_text ul li a {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
  font-weight: 500;
}
.main_slider_area .rev_slider .tp-revslider-mainul li .infor_text ul li a span {
  font-weight: 400;
  padding-left: 10px;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .infor_text
  ul
  li:first-child
  a
  span {
  padding-left: 55px;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .infor_text
  ul
  li:nth-child(2)
  a
  span {
  padding-left: 63px;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .infor_text
  ul
  li:nth-child(3)
  a
  span {
  padding-left: 62px;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .infor_text
  ul
  li:last-child {
  margin-bottom: 0px;
}
.main_slider_area .rev_slider .tp-revslider-mainul li .share_text ul li {
  display: inline-block;
  margin-right: 34px;
}
.main_slider_area .rev_slider .tp-revslider-mainul li .share_text ul li a {
  color: #888890;
  font-size: 16px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .share_text
  ul
  li:last-child {
  margin-right: 0px;
}
.main_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .share_text
  ul
  li:hover
  a {
  color: #1a1a26;
}
.main_slider_area .rev_slider .tp-revslider-mainul li .slider_title {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 25px;
}
.main_slider_area .rev_slider .tp-revslider-mainul li .slider_title:before {
  content: "";
  background: #6ed2e6;
  height: 2px;
  width: 50px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}
.main_slider_area .rev_slider .tp-revslider-mainul li .slider_title h4 {
  font-size: 24px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  display: block;
}
.main_slider_area .rev_slider .tp-revslider-mainul li .slider_title p {
  font-size: 14px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-top: 5px;
}
.main_slider_area .rev_slider .tp-leftarrow {
  height: 54px;
  width: 54px;
  background: #6ed2e6;
  border-radius: 50%;
  border: 1px solid #6ed2e6;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  z-index: 21;
  position: absolute;
}
.main_slider_area .rev_slider .tp-leftarrow:before {
  content: "\e879";
  font-family: "Linearicons-Free";
  font-size: 20px;
  line-height: 54px;
  width: 100%;
  height: 100%;
  color: #fff;
  position: absolute;
  left: 15px;
}
.main_slider_area .rev_slider .tp-leftarrow:hover {
  border: 1px solid #fff;
  background: #fff;
  color: #6ed2e6;
}
.main_slider_area .rev_slider .tp-leftarrow:hover:before {
  color: #6ed2e6;
}
.main_slider_area .rev_slider .tp-rightarrow {
  height: 54px;
  width: 54px;
  background: #6ed2e6;
  border-radius: 50%;
  border: 1px solid #6ed2e6;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  z-index: 21;
  position: absolute;
}
.main_slider_area .rev_slider .tp-rightarrow:before {
  content: "\e87a";
  font-family: "Linearicons-Free";
  font-size: 20px;
  line-height: 54px;
  color: #fff;
  position: absolute;
  left: 15px;
}
.main_slider_area .rev_slider .tp-rightarrow:hover {
  border: 1px solid #fff;
  background: #fff;
}
.main_slider_area .rev_slider .tp-rightarrow:hover:before {
  color: #6ed2e6;
}
.main_slider_area .rev_slider .tp-bullets .tp-bullet {
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background: #c3c1bc;
}
.main_slider_area .rev_slider .tp-bullets .tp-bullet.selected,
.main_slider_area .rev_slider .tp-bullets .tp-bullet:hover {
  background: #fff;
}
.main_slider_area
  .rev_slider
  .tp-tabs.inner
  .tp-tab-mask
  .tp-tabs-inner-wrapper
  .tp-tab {
  border: 2px solid #414140;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  text-align: center;
  line-height: 28px;
  opacity: 1;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.main_slider_area
  .rev_slider
  .tp-tabs.inner
  .tp-tab-mask
  .tp-tabs-inner-wrapper
  .tp-tab.selected,
.main_slider_area
  .rev_slider
  .tp-tabs.inner
  .tp-tab-mask
  .tp-tabs-inner-wrapper
  .tp-tab:hover {
  background: white;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  border: 2px solid #554e48;
  font-size: 14px;
}
.main_slider_area #portfolio-slider .tp-rightarrow {
  background: transparent;
  border-radius: 50%;
  border: none;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  z-index: 25;
  width: 20px;
  top: 56% !important;
}
.main_slider_area #portfolio-slider .tp-rightarrow:before {
  content: "\f106";
  font-family: "Linearicons-Free";
  font-size: 16px;
  font: normal normal normal 16px/1 FontAwesome;
}
.main_slider_area #portfolio-slider .tp-rightarrow:hover:before {
  color: #6ed2e6;
}
.main_slider_area #portfolio-slider .tp-leftarrow {
  background: transparent;
  border-radius: 50%;
  border: none;
  width: 20px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  z-index: 25;
  top: 56% !important;
}
.main_slider_area #portfolio-slider .tp-leftarrow:before {
  content: "\f107";
  font: normal normal normal 16px/1 FontAwesome;
}
.main_slider_area #portfolio-slider .tp-leftarrow:hover:before {
  color: #6ed2e6;
}
.main_slider_area
  #portfolio-slider
  .tp-tabs.inner
  .tp-tab-mask
  .tp-tabs-inner-wrapper
  .tp-tab {
  border: none;
  color: #fff;
  background: transparent;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  opacity: 1;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.main_slider_area
  #portfolio-slider
  .tp-tabs.inner
  .tp-tab-mask
  .tp-tabs-inner-wrapper
  .tp-tab.selected,
.main_slider_area
  #portfolio-slider
  .tp-tabs.inner
  .tp-tab-mask
  .tp-tabs-inner-wrapper
  .tp-tab:hover {
  background: transparent;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
}
.portfolio_f {
  position: absolute;
  bottom: 35px;
  width: 100%;
  left: 0px;
  z-index: 10;
  display: none;
}
.portfolio_f .pull-left ul li,
.portfolio_f .pull-right ul li {
  display: inline-block;
  margin-right: 25px;
}
.portfolio_f .pull-left ul li a,
.portfolio_f .pull-right ul li a {
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  position: relative;
}
.portfolio_f .pull-left ul li a:before,
.portfolio_f .pull-right ul li a:before {
  content: "";
  background: #fff;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.portfolio_f .pull-left ul li:last-child,
.portfolio_f .pull-right ul li:last-child {
  margin-right: 0px;
}
.portfolio_f .pull-left ul li:hover a:before,
.portfolio_f .pull-right ul li:hover a:before {
  opacity: 1;
}
.customer_area {
  background: #f8f8f8;
  padding: 100px 0px;
}
.customer_area .main_title {
  padding-bottom: 40px;
}
.customer_slider .item {
  padding: 0px 15px 15px 15px;
}
.customer_slider .item .customer_item_inner {
  margin-top: 40px;
  background: #fff;
  padding: 0px 30px;
  -webkit-box-shadow: 0px 0px 32px -1px #e1e1e1;
  -moz-box-shadow: 0px 0px 32px -1px #e1e1e1;
  box-shadow: 0px 0px 32px -1px #e1e1e1;
}
.customer_slider .item .customer_item_inner img {
  width: auto;
  max-width: 100%;
  position: relative;
  top: -40px;
}
.customer_slider .item .customer_item_inner p {
  font-family: "Roboto", serif;
  font-size: 18px;
  line-height: 32px;
  color: #888890;
  font-style: italic;
  margin-top: -15px;
}
.customer_slider .item .customer_item_inner h4 {
  font-size: 18px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  padding: 28px 0px 10px 0px;
}
.customer_slider .item .customer_item_inner h5 {
  font-size: 14px;
  color: #6ed2e6;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-bottom: 55px;
}
.customer_slider .owl-nav {
  text-align: center;
  margin-top: 47px;
}
.customer_slider .owl-nav .owl-next,
.customer_slider .owl-nav .owl-prev {
  width: 42px;
  height: 42px;
  text-align: center;
  font-size: 14px;
  line-height: 42px;
  color: #1a1a26;
  background: #fff;
  display: inline-block;
  margin: 0px 3px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.customer_slider .owl-nav .owl-next:hover,
.customer_slider .owl-nav .owl-prev:hover {
  background: #6ed2e6;
  color: #fff;
}
.clients_logo_slider .item {
  text-align: center;
  margin-top: 65px;
}
.clients_logo_slider .item img {
  width: auto;
  max-width: 100%;
  margin: auto;
}
.customer_saying_area .left_customer_exp {
  width: calc(50% - 215px);
  float: left;
  min-height: 722px;
}
.customer_saying_area .right_customer_divider {
  width: calc(50% + 215px);
  float: right;
  overflow: hidden;
  /* background: url("../img/saying-slider/saying-slider-bg.jpg") no-repeat scroll center center; */
  min-height: 722px;
}
.customer_saying_area .right_customer_divider .sec_title {
  text-align: center;
  padding-top: 100px;
}
.customer_saying_area .right_customer_divider .sec_title h2:before {
  display: none;
}
.saying_slider .item .saying_item {
  text-align: center;
  color: #fff;
  max-width: 685px;
  margin: auto;
}
.saying_slider .item .saying_item img {
  max-width: 100%;
  width: auto;
  display: inline-block;
  text-align: center;
}
.saying_slider .item .saying_item p {
  font-family: "Roboto", serif;
  font-style: italic;
  font-size: 18px;
  line-height: 32px;
  padding: 30px 0px 40px 0px;
}
.saying_slider .item .saying_item h4 {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  padding-bottom: 10px;
}
.saying_slider .item .saying_item h5 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.saying_slider .owl-next,
.saying_slider .owl-prev {
  position: absolute;
  bottom: -62px;
  z-index: 5;
  height: 32px;
  width: 32px;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  line-height: 32px;
  text-align: center;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  cursor: pointer;
}
.saying_slider .owl-next:hover,
.saying_slider .owl-prev:hover {
  background: rgba(255, 255, 255, 0.5);
}
.saying_slider .owl-next {
  left: 50%;
  margin-right: -34.5px;
}
.saying_slider .owl-prev {
  left: 50%;
  margin-left: -34.5px;
}
.exp_item {
  width: calc(100% / 2);
  float: left;
  background: #f8f8f8;
  border-bottom: 1px solid #ececec;
  border-right: 1px solid #ececec;
  text-align: center;
  min-height: 240px;
  padding-top: 60px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  position: relative;
}
.exp_item:before {
  content: "";
  -webkit-box-shadow: 0px 0px 50px -16px rgba(30, 30, 31, 0.5);
  -moz-box-shadow: 0px 0px 50px -16px rgba(30, 30, 31, 0.5);
  box-shadow: 0px 0px 50px -16px rgba(30, 30, 31, 0.5);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  opacity: 0;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.exp_item h3 {
  font-size: 42px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
}
.exp_item h5 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
  padding: 15px 0px;
}
.exp_item:nth-child(2n) {
  border-right: 0px;
}
.exp_item:nth-last-child(-n + 2) {
  border-bottom: none;
}
.exp_item:hover {
  background: #f8f8f8;
}
.exp_item:hover:before {
  opacity: 1;
}
.single_img_area {
  min-height: 100vh;
  /* background: url("../img/slider/single-image.jpg") no-repeat scroll center 100%; */
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
}
.single_img_area:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(61, 61, 61, 0.58);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.kl_single_content {
  color: #fff;
  text-align: center;
  padding-top: 252px;
  padding-bottom: 165px;
}
.kl_single_content h5 {
  font: 700 32px/60px "Roboto", serif;
  font-style: italic;
  padding-bottom: 10px;
}
.kl_single_content h2 {
  font: 500 60px/70px "Roboto", sans-serif;
}
.kl_single_content p {
  font: 400 14px/24px "Roboto", sans-serif;
  max-width: 710px;
  margin: 25px auto 50px;
}
.kl_single_content .c_btn,
.kl_single_content .l_btn {
  margin: 0px 8px;
}
.kl_services_items {
  margin-bottom: 56px;
}
.kl_services_items .service_item {
  font-family: "Roboto", sans-serif;
  color: #fff;
}
.kl_services_items .service_item h2 {
  font-size: 16px;
  font-weight: 500;
  padding: 20px 0px 13px;
}
.kl_services_items .service_item p {
  font-weight: 400;
  line-height: 24px;
}
.corporate_slider_area .rev_slider .tp-revslider-mainul li .slider_text_box {
  color: #fff;
}
.corporate_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .slider_text_box
  .slide_h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}
.corporate_slider_area
  .rev_slider
  .tp-revslider-mainul
  li
  .slider_text_box
  .secand_text {
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
}
.multi_slider .rev_slider .tp-revslider-mainul li .slotholder:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
}
.multi_slider .rev_slider .tp-revslider-mainul li .slider_text_box .first_text {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.multi_slider
  .rev_slider
  .tp-revslider-mainul
  li
  .slider_text_box
  .secand_text
  span {
  font-weight: 600;
  text-transform: uppercase;
}
.multi_slider .rev_slider .tp-leftarrow,
.multi_slider .rev_slider .tp-rightarrow {
  border-radius: 0px;
  background: #fff;
  border: 0px;
}
.multi_slider .rev_slider .tp-leftarrow:before,
.multi_slider .rev_slider .tp-rightarrow:before {
  color: #888890;
}
.multi_slider .rev_slider .tp-leftarrow:hover,
.multi_slider .rev_slider .tp-rightarrow:hover {
  background: #6ed2e6;
  border: 0px;
}
.multi_slider .rev_slider .tp-leftarrow:hover:before,
.multi_slider .rev_slider .tp-rightarrow:hover:before {
  color: #fff;
}
.customer_saying_area_two {
  /* background: url("../img/customer/cus_bg.jpg") no-repeat scroll center 0; */
  background-size: cover;
  padding-top: 90px;
}
.customer_saying_area_two .sec_title {
  text-align: center;
  padding-bottom: 30px;
}
.customer_saying_area_two .sec_title h2:before {
  display: none;
}
.customer_saying_area_two .clients_logo {
  margin-top: 144px;
}
.customer_saying_area_two .clients_logo .clogo_item {
  width: calc(100% / 6);
  float: left;
  text-align: center;
  border-top: 1px solid #333e4e;
  border-right: 1px solid #333e4e;
  padding: 46px 0px;
}
.customer_saying_area_two .clients_logo .clogo_item:last-child {
  border-right: 0px;
}
.banner_area {
  /*background: url("../img/banner/banner-img1.jpg") no-repeat scroll center center;*/
  background-size: cover;
  text-align: center;
  min-height: 100px;
  position: relative;
  z-index: 2;
}
.banner_area:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  z-index: -1;
}
.banner_text_inner {
  padding-top: 215px;
}
.banner_text_inner a {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
}
.banner_text_inner h3 {
  font-size: 60px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 15px;
}
.breadcrumb_area {
  /* background: url("../img/single-portfolio/porfolio_baner.jpg") no-repeat scroll center 0; */
  background-size: cover;
  padding: 307px 0px 320px;
}
.breadcrumb_area .breadcrumb_text h3 {
  font-size: 36px;
  line-height: 68px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}
.breadcrumb_area .breadcrumb_text a {
  font: 16px;
  line-height: 24px;
  font-family: "Roboto", serif;
  color: #fff;
  font-style: italic;
  display: inline-block;
}
.breadcrumb_area .breadcrumb_text a + a:before {
  content: "";
  width: 1px;
  height: 12px;
  background: #fff;
  display: inline-block;
  -webkit-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  transform: rotate(25deg);
  margin: 0px 7px 0px 4px;
}
.feature_area {
  padding: 100px 0px 95px 0px;
}
.feature_area .main_title {
  padding-bottom: 40px;
}
.feature_item_inner {
  margin-top: -45px;
  overflow: hidden;
}
.feature_item_inner .media {
  margin-top: 45px;
  padding-top: 5px;
  padding-left: 5px;
}
.feature_item_inner .media .media-left {
  padding-right: 30px;
}
.feature_item_inner .media .media-left i {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  text-align: center;
  line-height: 70px;
  display: block;
  font-size: 25px;
  color: #1a1a26;
  background-image: -moz-linear-gradient(45deg, #a8edea 0%, #fed6e3 100%);
  background-image: -webkit-linear-gradient(45deg, #a8edea 0%, #fed6e3 100%);
  background-image: -ms-linear-gradient(45deg, #a8edea 0%, #fed6e3 100%);
  -webkit-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  position: relative;
  z-index: 1;
}
.feature_item_inner .media .media-left i:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #6ed2e6;
  border-radius: 50%;
  opacity: 1;
  opacity: 0;
  -webkit-transition: all 0.6s ease-in;
  -o-transition: all 0.6s ease-in;
  transition: all 0.6s ease-in;
  z-index: -1;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.feature_item_inner .media .media-body h4 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
  padding-bottom: 12px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.feature_item_inner .media .media-body p {
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  font-size: 14px;
  color: #888890;
}
.feature_item_inner .media:hover .media-left i {
  color: #fff;
}
.feature_item_inner .media:hover .media-left i:after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.feature_item_inner .media:hover .media-body h4 {
  color: #6ed2e6;
}
.find_more_area {
  background: #f07f84;
}
.find_more_area .pull-left h5 {
  font-size: 24px;
  font-family: "Roboto", serif;
  font-style: italic;
  color: #fff;
  padding-bottom: 12px;
  padding-top: 52px;
}
.find_more_area .pull-left h3 {
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  color: #fff;
}
.find_more_area .pull-right {
  padding: 60px 0px;
}
.find_more_area .pull-right .l_btn {
  width: 210px;
}
.choose_us_area {
  overflow: hidden;
}
.choose_us_area .choose_left {
  width: calc(50%);
  float: left;
  overflow: hidden;
}
.choose_us_area .choose_right {
  width: calc(50%);
  float: right;
  overflow: hidden;
}
/* .100wi {
	width: calc(100%);
} */
.choose_us_area .choose_right .choose_right_inner {
  max-width: 1000px;
  margin-left: 115px;
}
.choose_us_area .choose_right .choose_right_inner .full_left_sec_title {
  padding-top: 20px;
  padding-bottom: 55px;
}
.bas {
  font-size: 19px;
  padding-top: 7px;
  color: #000;
}
.text a {
  color: #000;
}
.texte a {
  color: #000;
  font-size: 13px;
}
.basalt {
  font-size: 17px;
  padding-top: 4px;
  color: #000;
}
.text {
  font-size: 14px;
  color: #000;
}
.pad0 {
  margin-top: -143px;
}
.choose_collaps .panel-group .panel-default {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0px;
  border-color: #ececec;
  margin-top: 15px;
}
.choose_collaps .panel-group .panel-default .panel-heading {
  padding: 0px;
  border: none;
  border-radius: 0px;
  background: transparent;
  padding: 0px 15px;
}
.choose_collaps .panel-group .panel-default .panel-heading .panel-title a {
  line-height: 50px;
  display: block;
  font-size: 14px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}
.choose_collaps .panel-group .panel-default .panel-heading .panel-title a i {
  float: right;
  line-height: 50px;
  color: #1a1a26;
  display: none;
}
.choose_collaps
  .panel-group
  .panel-default
  .panel-heading
  .panel-title
  a
  i
  + i {
  display: block;
}
.choose_collaps
  .panel-group
  .panel-default
  .panel-heading
  .panel-title
  a.collapsed
  i {
  display: block;
}
.choose_collaps
  .panel-group
  .panel-default
  .panel-heading
  .panel-title
  a.collapsed
  i
  + i {
  display: none;
}
.choose_collaps .panel-group .panel-default .panel-collapse .panel-body {
  border: none;
  font-size: 14px;
  line-height: 24px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-top: 4px;
  padding-bottom: 40px;
}
.choose_collaps .panel-group .panel-default:first-child {
  margin-top: 0px;
}
.choose_left_inner {
  max-width: 585px;
  float: right;
  padding-right: 114px;
}
.choose_left_inner .full_left_sec_title {
  padding-top: 100px;
}
.choose_left_inner .dark_skill {
  padding-top: 30px;
}
.choose_left_inner .dark_skill .skill_item .bt-skill .bt-skillholder {
  background: #ececec;
}
.find_more_bg {
  background: #6ed2e6;
  padding: 45px 0px;
}
.find_more_bg .pull-left h3 {
  line-height: 60px;
}
.find_more_bg .pull-right {
  padding: 0px;
}
.find_more_bg .pull-right .l_btn:hover {
  background: #fff;
  color: #1a1a26;
}
.kl_mult_features_area {
  padding: 95px 0px;
}
.mult_features .media {
  padding-top: 43px;
}
.mult_features .media .media-left {
  padding-right: 30px;
}
.mult_features .media .media-left i {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background: #6ed2e6;
  text-align: center;
  display: block;
  font-size: 22px;
  color: #fff;
  line-height: 68px;
  position: relative;
}
.mult_features .media .media-left i:after {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  bottom: -9px;
  background: #6ed2e6;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 50%;
  margin-left: -9px;
}
.mult_features .media .media-body h4 {
  font: 500 16px "Roboto", sans-serif;
  line-height: 20px;
  color: #1a1a26;
  text-transform: capitalize;
  padding-bottom: 12px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.mult_features .media .media-body h4:hover {
  color: #6ed2e6;
}
.mult_features .media .media-body p {
  font-size: 14px;
  line-height: 24px;
  color: #888890;
}
.description_text {
  padding-left: 100px;
  padding-top: 155px;
  padding-bottom: 55px;
}
.description_text h6 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
  padding-bottom: 20px;
}
.description_text h4 {
  font-size: 36px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
}
.description_text h4 span {
  font-family: "Roboto", serif;
  font-style: italic;
  font-weight: bold;
}
.description_text h4 .f_light {
  font-family: "Roboto", serif;
  font-style: italic;
  font-weight: 300;
}
.description_text p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #888890;
  line-height: 24px;
  max-width: 660px;
  padding-top: 20px;
}
.single_text .description_text {
  padding-top: 100px;
  padding-bottom: 90px;
}
.single_text .description_text h4 {
  font-size: 36px;
  line-height: 48px;
  font-family: "Roboto", sans-serif;
  color: #888890;
  max-width: 800px;
}
.single_text .description_text h4 .ita_f {
  font-family: "Roboto", serif;
  font-style: italic;
  color: #1a1a26;
  font-weight: 500;
}
.single_text .description_text h4 .black_t {
  color: #1a1a26;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.single_text .description_text h4 .ita_b {
  color: #1a1a26;
  font-family: "Roboto", serif;
}
.about_area {
  overflow: hidden;
  clear: both;
}
.about_area .about_left_side {
  width: 50%;
  float: left;
  overflow: hidden;
  min-height: 850px;
  background: #f8f8f8;
  padding-left: 60px;
  padding-top: 100px;
}
.about_area .about_left_side .sec_b_title {
  padding-bottom: 57px;
  max-width: 785px;
}
.about_area .about_right_side {
  width: 50%;
  float: right;
  overflow: hidden;
}
.about_area .about_right_side .about_r_part_1 {
  width: 50%;
  float: left;
  /* background: url("../img/about/about-1.jpg") no-repeat scroll center center; */
  background-size: cover;
  overflow: hidden;
  min-height: 850px;
  position: relative;
}
.about_area .about_right_side .about_r_part_1.ab_part_3 {
  /* background: url("../img/about/about-3.jpg") no-repeat scroll center center */
}
.about_area .about_right_side .about_r_part_2 {
  width: 50%;
  float: right;
  /* background: url("../img/about/about-2.jpg") no-repeat scroll center center; */
  background-size: cover;
  overflow: hidden;
  min-height: 850px;
  position: relative;
}
.about_area .about_right_side .about_r_part_2.ab_part_4 {
  /* background: url("../img/about/about-4.jpg") no-repeat scroll center center */
}
.experience_area {
  overflow: hidden;
}
.experience_area .experience_box {
  float: left;
  overflow: hidden;
  position: relative;
}
.experience_area .experience_count {
  padding-left: 50px;
  overflow: hidden;
}
.experience_area .experience_count .media {
  margin-top: 30px;
}
.experience_area .experience_count .media .media-left {
  padding-right: 15px;
}
.experience_area .experience_count .media .media-body {
  width: auto;
}
.experience_area .experience_count .media .media-body h4 {
  font-size: 42px;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
}
.experience_area .experience_count .media .media-body p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
}
.experience_area .experience_count .media:first-child {
  margin-top: 0px;
}
.ex_box {
  position: absolute;
  padding: 10px;
  z-index: 3;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.ex_box .ex_box_inner {
  height: 100%;
  width: 100%;
  background: #fff;
}
.ex_box .ex_box_inner h4 {
  position: absolute;
  bottom: 45px;
  left: 30px;
  width: 201px;
  font-size: 36px;
  line-height: 42px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
}
.ex_box .ex_box_inner h4 span {
  color: #6ed2e6;
}
.mission_text {
  vertical-align: bottom;
  position: absolute;
  left: 0px;
  bottom: 100px;
  padding: 0px 50px;
  color: #fff;
}
.mission_text h5 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-bottom: 5px;
}
.mission_text h4 {
  font-size: 36px;
  font-family: "Roboto", sans-serif;
}
.mission_text p {
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-top: 25px;
}
.mission_text .l_btn {
  margin-top: 50px;
}
.kl_about_area {
  position: relative;
  padding: 100px 0px;
}
.kl_about_area:before {
  content: "";
  /*background: url("../img/single-image_pattern.jpg") no-repeat center left;*/
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
}
.kl_about_area.business_about:before {
  /*background: url("../img/about/about-pattern.jpg") no-repeat center left*/
}
.kl_video_img {
  padding: 10px;
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0px 0px 50px -20px rgba(30, 30, 31, 0.5);
  -moz-box-shadow: 0px 0px 50px -20px rgba(30, 30, 31, 0.5);
  box-shadow: 0px 0px 50px -20px rgba(30, 30, 31, 0.5);
}
.kl_video_img img {
  max-width: 100%;
}
.kl_video_img .play_btn {
  position: absolute;
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #1a1a26;
  text-align: center;
  line-height: 60px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.kl_video_img .play_btn:hover {
  background: #6ed2e6;
  color: #fff;
}
.kl_about_text p {
  color: #888890;
  padding-top: 25px;
}
.kl_about_text .check_btn {
  border: 0px;
  margin-top: 50px;
}
.we_do_area {
  background: #f8f8f8;
  overflow: hidden;
  clear: both;
  padding: 100px 0px;
}
.we_do_area .check_btn {
  margin-top: 55px;
}
.we_do_area .we_do_inner {
  max-width: 470px;
}
.do_slider .owl-next,
.do_slider .owl-prev {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 5;
  height: 32px;
  width: 32px;
  background: rgba(26, 26, 38, 0.5);
  color: #fff;
  line-height: 32px;
  text-align: center;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  cursor: pointer;
}
.do_slider .owl-next:hover,
.do_slider .owl-prev:hover {
  background: #6ed2e6;
}
.do_slider .owl-next {
  right: 0px;
}
.do_slider .owl-prev {
  left: 0px;
}
.buy_area {
  text-align: center;
  overflow: hidden;
}
.buy_area .divimage {
  position: absolute;
}
.buy_area .buy_inner_content {
  position: relative;
  padding: 160px 0px;
}
.buy_area .buy_inner_content h5 {
  color: #fff;
  font-size: 24px;
  font-family: "Roboto", serif;
  font-style: italic;
}
.buy_area .buy_inner_content h4 {
  font-size: 36px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  padding: 27px 0px 47px 0px;
}
.buy_area .buy_inner_content .l_btn {
  width: 160px;
  margin-right: 12px;
}
.buy_area .buy_inner_content .quote_btn {
  height: 60px;
  line-height: 60px;
  width: 160px;
}
.about_us_text_area {
  padding: 100px 0px;
}
.about_year_text h5 {
  font-size: 16px;
  color: #888890;
  font-family: "Roboto", serif;
}
.about_year_text h3 {
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
  line-height: 42px;
  padding: 10px 0px 10px 0px;
}
.about_year_text p {
  font-size: 14px;
  line-height: 24px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.about_year_text p + p {
  padding-top: 25px;
}
.about_year_text a {
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  color: #6ed2e6;
  font-weight: 600;
  margin-top: 30px;
  display: block;
}
.about_s_item_inner .about_s_item {
  text-align: right;
  margin-bottom: 52px;
}
.about_s_item_inner .about_s_item img {
  padding-bottom: 20px;
}
.about_s_item_inner .about_s_item h4 {
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  padding-bottom: 12px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.about_s_item_inner .about_s_item h4:hover {
  color: #6ed2e6;
}
.about_s_item_inner .about_s_item p {
  font-size: 14px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 24px;
}
.about_item_inner {
  margin-top: 100px;
}
.about_item_inner .about_items {
  text-align: center;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  padding-bottom: 45px;
}
.about_item_inner .about_items img {
  max-width: 100%;
}
.about_item_inner .about_items h4 {
  font-size: 18px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  padding: 25px 0px 7px 0px;
}
.about_item_inner .about_items p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  color: #888890;
  font-weight: 400;
  max-width: 270px;
  margin: auto;
  padding-bottom: 22px;
}
.about_item_inner .about_items:hover {
  -webkit-box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.1);
}
.who_we_area {
  padding: 100px 0px 52px;
}
.who_we_area .who_text p {
  font: 400 14px/24px "Roboto", sans-serif;
  color: #888890;
  padding-top: 27px;
}
.who_we_area .who_text .view_btn {
  font: 600 14px "Roboto", sans-serif;
  color: #6ed2e6;
  text-transform: uppercase;
  margin-top: 28px;
  display: inline-block;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.who_we_area .who_text .view_btn:hover {
  color: #1a1a26;
}
.who_we_area .experience_box {
  position: relative;
}
.who_we_area .experience_box .ex_box .ex_box_inner h4 {
  width: 100%;
}
.who_we_area .experience_count {
  padding-left: 0px;
  padding-top: 28px;
}
.who_we_area .experience_count .media {
  width: calc(100% / 2);
  float: left;
  margin-top: 33px;
}
.experience_count {
  padding-left: 50px;
  overflow: hidden;
}
.experience_count .media {
  margin-top: 30px;
}
.experience_count .media .media-left {
  padding-right: 15px;
}
.experience_count .media .media-body {
  width: auto;
}
.experience_count .media .media-body h4 {
  font-size: 42px;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
}
.experience_count .media .media-body p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
}
.co_service_area {
  padding-bottom: 44px;
}
.co_service_area .about_s_item_inner {
  overflow: hidden;
  border-top: 1px solid #ececec;
  padding-top: 60px;
}
.kl_mul_we_do {
  background: #fff;
}
.kl_mul_we_do .sec_b_title {
  padding-bottom: 25px;
}
.kl_mul_we_do .skill_item .bt-skill .bt-skillholder {
  background: #f9f9f9;
}
.kl_design_tab .design_tab {
  padding-top: 28px;
}
.kl_design_tab .design_tab .tab_fillter {
  border-bottom: 3px solid #ececec;
}
.kl_design_tab .design_tab .tab_fillter li {
  margin: 0px;
}
.kl_design_tab .design_tab .tab_fillter li a {
  font: 400 14px/22px "Roboto", sans-serif;
  color: #888890;
  background: #ececec;
  border-radius: 0px;
  border: 0px;
  padding: 9px 23px;
  margin-right: 5px;
}
.kl_design_tab .design_tab .tab_fillter li a i {
  padding-right: 7px;
}
.kl_design_tab .design_tab .tab_fillter li.active a {
  background: #6ed2e6;
  color: #fff;
}
.kl_design_tab .design_tab .design_content {
  border: 1px solid #ececec;
  border-top: 0px;
  padding: 20px 15px;
}
.kl_design_tab .design_tab .design_content .tab-pane .media {
  padding: 20px 0px;
}
.kl_design_tab .design_tab .design_content .tab-pane .media .media-left {
  padding-right: 30px;
}
.kl_design_tab .design_tab .design_content .tab-pane .media .media-left i {
  width: 60px;
  height: 60px;
  border: 1px solid #ececec;
  text-align: center;
  font-size: 21px;
  line-height: 60px;
  color: #1a1a26;
  border-radius: 50%;
}
.kl_design_tab .design_tab .design_content .tab-pane .media .media-body {
  font: 400 12px/24px "Roboto", sans-serif;
  color: #888890;
  vertical-align: middle;
}
.kl_design_tab .design_tab .design_content .tab-pane .media + .media {
  border-top: 1px solid #ececec;
  margin: 0px;
}
.latest_project_area {
  padding: 100px 0px;
}
.latest_project_area .main_title {
  padding-bottom: 40px;
}
.latest_menu {
  text-align: center;
  padding-bottom: 28px;
}
.latest_menu ul li {
  display: inline-block;
  margin-right: 38px;
}
.latest_menu ul li a {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #1a1a26;
  position: relative;
  display: inline-block;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.latest_menu ul li a:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #888890;
  position: absolute;
  left: 0px;
  bottom: 0px;
  opacity: 0;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.latest_menu ul li:last-child {
  margin-right: 0px;
}
.latest_menu ul li.active a,
.latest_menu ul li:hover a {
  color: #888890;
}
.latest_menu ul li.active a:before,
.latest_menu ul li:hover a:before {
  opacity: 1;
}
.l_p_i {
  margin-top: 30px;
}
.l_project_item {
  position: relative;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.l_project_item img {
  max-width: 100%;
}
.l_project_item .l_project_hover {
  position: absolute;
  top: 0px;
  left: 0px;
  border: 10px solid #fff;
  bottom: 0px;
  right: 0px;
  opacity: 0;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.l_project_item .l_project_hover:before {
  content: "";
  background: rgba(240, 127, 132, 0.8);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.l_project_item .l_project_hover .l_project_h_inner {
  position: absolute;
  left: 0px;
  bottom: 25px;
  padding-left: 60px;
  opacity: 0;
  padding-right: 40px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.l_project_item .l_project_hover .l_project_h_inner h4 {
  font-size: 16px;
  color: #fff;
  font-family: "Roboto", sans-serif;
}
.l_project_item .l_project_hover .l_project_h_inner p {
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  font-weight: 400;
  padding: 13px 0px 25px 0px;
}
.l_project_item .l_project_hover .l_project_h_inner a {
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  font-weight: 400;
}
.l_project_item .l_project_hover .l_project_h_inner a i {
  padding-left: 5px;
}
.l_project_item:hover {
  -webkit-box-shadow: 0px 0px 50px -2px #e1e1e1;
  -moz-box-shadow: 0px 0px 50px -2px #e1e1e1;
  box-shadow: 0px 0px 50px -2px #e1e1e1;
}
.l_project_item:hover .l_project_hover {
  opacity: 1;
}
.l_project_item:hover .l_project_hover .l_project_h_inner {
  opacity: 1;
  padding-left: 40px;
}
.lt_title {
  font-size: 32px;
  color: #1e1e1f;
  text-transform: uppercase;
  font-weight: 500;
}
.kl_latest_porject_area {
  /* background: url("../img/l-project/pat.jpg") repeat scroll 100%; */
  padding: 95px 0px 150px;
}
.kl_latest_porject_area .latest_menu {
  text-align: right;
  padding-bottom: 0px;
}
.kl_latest_porject_area .lt_project .l_p_i {
  margin-top: 30px;
}
.latest_full_project {
  overflow: hidden;
  clear: both;
}
.latest_full_project .l_left_project {
  width: calc(50% - 192px);
  float: left;
  overflow: hidden;
  background: #1a1a26;
  min-height: 640px;
  padding-top: 100px;
}
.latest_full_project .l_right_project {
  width: calc(90% + 192px);
  float: right;
  overflow: hidden;
}
.l_left_project_text {
  max-width: 585px;
  margin: auto;
}
.l_left_project_text h5 {
  font-size: 16px;
  font-family: "Roboto", serif;
  color: #888890;
}
.l_left_project_text h3 {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  padding-bottom: 45px;
  padding-top: 15px;
}
.l_left_project_text p {
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  color: #888890;
}
.l_left_project_text p + p {
  padding-top: 25px;
}
.l_left_project_text .c_btn {
  width: 210px;
  height: 50px;
  line-height: 50px;
  margin-top: 50px;
}
.l_r_project_item {
  width: calc(100% / 5);
  float: left;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.l_r_project_item:before {
  content: "";
  background: rgba(26, 26, 38, 0.8);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.l_r_project_item .plus_project {
  position: absolute;
  top: 47%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: #fff;
  z-index: 2;
  overflow: hidden;
  opacity: 0;
  transition: all 400ms linear 0s;
  left: 50%;
}
.l_r_project_item .plus_project i {
  height: 54px;
  width: 54px;
  background: #fff;
  color: #1a1a26;
  display: inline-block;
  line-height: 54px;
  border-radius: 50%;
  font-size: 16px;
}
.l_r_project_item .l_project_hover {
  position: absolute;
  bottom: 25px;
  left: 10px;
  color: #fff;
  z-index: 2;
  opacity: 0;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.l_r_project_item .l_project_hover h4 {
  font-size: 16px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  padding-bottom: 8px;
}
.l_r_project_item .l_project_hover h5 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.l_r_project_item:hover:before {
  opacity: 1;
}
.l_r_project_item:hover .plus_project {
  opacity: 1;
}
.l_r_project_item:hover .l_project_hover {
  opacity: 1;
  left: 30px;
}
.count_b_area {
  background: #f9f9f9;
}
.exp_item.count_b_item {
  background: transparent;
  border: none;
  width: 100%;
}
.exp_item.count_b_item:before {
  opacity: 0;
}
.co_latest_pr_area {
  background: #f8f8f8;
  padding: 100px 0px;
}
.co_latest_pr {
  padding-top: 48px;
}
.co_latest_pr .co_latest_item {
  position: relative;
  cursor: pointer;
  margin-bottom: 30px;
}
.co_latest_pr .co_latest_item:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #1a1a26;
  opacity: 0;
  position: absolute;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transition: all 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  -o-transition: all 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  transition: all 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}
.co_latest_pr .co_latest_item img {
  max-width: 100%;
}
.co_latest_pr .co_latest_item .plus_project {
  position: absolute;
  top: 47%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  left: 0;
  z-index: 2;
  overflow: hidden;
}
.co_latest_pr .co_latest_item .plus_project i {
  height: 54px;
  width: 54px;
  background: #fff;
  color: #1a1a26;
  display: inline-block;
  line-height: 54px;
  border-radius: 50%;
  font-size: 16px;
  opacity: 1;
  text-align: center;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 0.8s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  -o-transition: all 0.8s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  transition: all 0.8s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}
.co_latest_pr .co_latest_item .l_project_hover {
  position: absolute;
  bottom: 0;
  font-family: "Roboto", sans-serif;
  color: #fff;
  background: rgba(26, 26, 38, 0.8);
  width: 100%;
  left: 0;
  padding: 27px 30px 23px;
}
.co_latest_pr .co_latest_item .l_project_hover h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
}
.co_latest_pr .co_latest_item .l_project_hover h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}
.co_latest_pr .co_latest_item:hover:before {
  opacity: 0.8;
}
.co_latest_pr .co_latest_item:hover .plus_project i {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.recent_work_area {
  padding: 97px 0px 100px;
  background: #f8f8f8;
}
.recent_work_area .main_title {
  padding-bottom: 35px;
}
.recent_work_area .lt_project .l_p_i {
  margin-top: 30px;
}
.recent_work_area .lt_project .l_project_item .l_project_hover:before {
  background: rgba(26, 26, 38, 0.8);
}
.price_area {
  padding: 100px 0px 85px 0px;
}
.price_area .main_title {
  padding-bottom: 55px;
}
.price_inner_item {
  min-height: 580px;
}
.price_inner_item .col-md-3:last-child .price_item {
  border-right: 1px solid #ececec;
}
.price_item {
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.price_item .price_head {
  text-align: center;
  padding: 28px 0px;
  border-bottom: 1px solid #ececec;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.price_item .price_head h3 {
  font-size: 18px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  letter-spacing: 5.4px;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.price_item .price_head h2 {
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: #1a1a26;
}
.price_item .price_head h2 span {
  font-size: 14px;
  font-family: "Roboto", serif;
  font-style: italic;
  color: #888890;
}
.price_item p {
  padding: 30px 30px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #888890;
}
.price_item .price_option {
  padding-left: 60px;
}
.price_item .price_option li a {
  font-size: 14px;
  line-height: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #1a1a26;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.price_item .price_option li a i {
  position: relative;
  left: -8px;
  color: #f07f84;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.price_item .price_option li:hover a {
  color: #f07f84;
}
.price_item .price_option li:hover a i {
  left: -4px;
}
.price_item .p_btn {
  margin: 55px auto 65px;
  display: block;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.price_item:hover {
  -webkit-box-shadow: 0px 0px 50px -20px #1e1e1f;
  -moz-box-shadow: 0px 0px 50px -20px #1e1e1f;
  box-shadow: 0px 0px 50px -20px #1e1e1f;
  margin-top: -15px;
}
.price_item:hover .price_head {
  padding-top: 43px;
}
.price_item:hover .p_btn {
  margin-bottom: 80px;
}
.kl_pricing_area {
  padding: 85px 0px 100px;
}
.kl_pricing_area .sec_title {
  color: #1a1a26;
  text-align: center;
}
.kl_pricing_area .sec_title h2:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #6ed2e6;
}
.kl_pricing_area .pricing_box {
  text-align: center;
  border: 1px solid #ececec;
  padding: 30px 0px 65px;
  margin-top: 40px;
}
.kl_pricing_area .pricing_box .pricing-header {
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
}
.kl_pricing_area .pricing_box .pricing-header .icon {
  /* background: url("../img/testimonial/polygon.png") no-repeat scroll center 0; */
  padding: 23px 0px 27px;
}
.kl_pricing_area .pricing_box .pricing-header .icon img {
  img-fill-color: #fff;
}
.kl_pricing_area .pricing_box .pricing-header h2 {
  font: 500 18px/36px "Roboto", sans-serif;
  color: #1a1a26;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.kl_pricing_area .pricing_box .pricing-header .packeg_typ {
  font: 600 36px "Roboto", sans-serif;
  color: #1a1a26;
}
.kl_pricing_area .pricing_box .pricing-header .packeg_typ small {
  font: 400 14px/32px "Roboto", serif;
  color: #888890;
  font-style: italic;
}
.kl_pricing_area .pricing_box p {
  font-size: 14px;
  line-height: 24px;
  color: #888890;
  padding: 25px 0px 26px;
}
.kl_pricing_area .pricing_box .plan-lists {
  margin-bottom: 52px;
}
.kl_pricing_area .pricing_box .plan-lists li {
  color: #1a1a26;
  line-height: 24px;
}
.kl_pricing_area .pricing_box .plan-lists li i {
  color: #f07f84;
  padding-right: 8px;
}
.team_members_area {
  /* background: url("../img/member/bember-bg.jpg") no-repeat fixed center center; */
  background-size: cover;
  position: relative;
  z-index: 2;
  padding: 185px 0px;
}
.team_members_area:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.team_members_area .team_details .c_btn {
  display: block;
  margin: 55px auto 0px;
  width: 230px;
}
.meet_team_area {
  padding: 100px 0px;
}
.meet_team_area .main_title {
  margin-bottom: 40px;
}
.meet_team_area .m_team_items {
  margin-top: -30px;
}
.m_team_inner {
  position: relative;
  z-index: 3;
  margin-top: 30px;
}
.m_team_inner img {
  max-width: 100%;
}
.m_team_inner .m_team_hover {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 10px solid #fff;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.m_team_inner .m_team_hover:before {
  content: "";
  background: rgba(240, 127, 132, 0.8);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.m_team_inner .m_team_hover .m_team_inner_h {
  position: absolute;
  left: 0px;
  width: 100%;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.m_team_inner .m_team_hover .m_team_inner_h h4 {
  font-size: 16px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  padding-bottom: 8px;
}
.m_team_inner .m_team_hover .m_team_inner_h h5 {
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-bottom: 15px;
}
.m_team_inner .m_team_hover .m_team_inner_h ul {
  overflow: hidden;
  clear: both;
}
.m_team_inner .m_team_hover .m_team_inner_h ul li {
  display: inline-block;
  margin-right: 18px;
  position: relative;
  bottom: -15px;
  -webkit-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
  opacity: 0;
}
.m_team_inner .m_team_hover .m_team_inner_h ul li a {
  font-size: 16px;
  color: #fff;
  -webkit-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
  line-height: normal;
}
.m_team_inner .m_team_hover .m_team_inner_h ul li:last-child {
  margin-right: 0px;
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.m_team_inner .m_team_hover .m_team_inner_h ul li:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.m_team_inner .m_team_hover .m_team_inner_h ul li:nth-child(3) {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.m_team_inner .m_team_hover .m_team_inner_h ul li:hover a {
  color: #1a1a26;
}
.m_team_inner:hover .m_team_hover {
  opacity: 1;
  -webkit-box-shadow: 0px 0px 50px -2px #e1e1e1;
  -moz-box-shadow: 0px 0px 50px -2px #e1e1e1;
  box-shadow: 0px 0px 50px -2px #e1e1e1;
}
.m_team_inner:hover .m_team_hover .m_team_inner_h ul li {
  opacity: 1;
  bottom: 0px;
}
.happy_clients_area {
  overflow: hidden;
  clear: both;
}
.happy_clients_area .happy_left_area {
  width: calc(50% + 214px);
  float: left;
  overflow: hidden;
  /* background: url("../img/happy-client-left-bg.jpg") no-repeat scroll center center; */
  background-size: cover;
  position: relative;
  z-index: 2;
  min-height: 722px;
}
.happy_clients_area .happy_left_area:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
  height: 100%;
}
.happy_clients_area .happy_left_area .main_w_r_title {
  padding-bottom: 60px;
  padding-top: 100px;
}
.happy_clients_area .happy_right_area {
  width: calc(50% - 214px);
  float: right;
  overflow: hidden;
}
.happy_clients_inner {
  max-width: 826px;
  margin: auto;
}
.happy_clients_inner .happy_clients_slider .item .happy_items {
  width: calc(100% / 3);
  float: left;
  overflow: hidden;
  text-align: center;
  line-height: 140px;
  border-bottom: 1px solid #282828;
  border-right: 1px solid #282828;
}
.happy_clients_inner
  .happy_clients_slider
  .item
  .happy_items:nth-last-child(-1n + 3) {
  border-bottom: 0px;
}
.happy_clients_inner
  .happy_clients_slider
  .item
  .happy_items:nth-last-child(3n + 1) {
  border-right: 0px;
}
.happy_clients_inner .happy_clients_slider .item .happy_items img {
  width: auto;
  max-width: 100%;
  display: inline-block;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.happy_clients_inner .happy_clients_slider .item .happy_items:hover img {
  opacity: 0.6;
}
.happy_clients_inner .happy_clients_slider .owl-dots {
  text-align: center;
  margin-top: 40px;
}
.happy_clients_inner .happy_clients_slider .owl-dots .owl-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #8d8c92;
  display: inline-block;
  margin: 5px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.happy_clients_inner .happy_clients_slider .owl-dots .owl-dot.active {
  background: #6ed2e6;
}
.happy_video {
  z-index: 2;
  position: relative;
}
.happy_video .happy_hover {
  position: absolute;
  left: 0px;
  width: 100%;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
}
.happy_video .happy_hover a {
  color: #fff;
  font-size: 24px;
  border: 1px solid #fff;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 48px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.happy_video .happy_hover a:hover {
  border-color: #6ed2e6;
  background: #6ed2e6;
}
.co_team_area {
  padding: 100px 0px 95px;
}
.co_team_item {
  text-align: center;
  font-family: "Roboto", sans-serif;
  margin-top: 50px;
  cursor: pointer;
}
.co_team_item .team_img {
  width: 170px;
  height: 170px;
  background: #ececec;
  border-radius: 50%;
  position: relative;
  border: 10px solid #ececec;
  overflow: hidden;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.co_team_item .team_img:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #1a1a26;
  opacity: 0;
  position: absolute;
  border-radius: 50%;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  z-index: 1;
}
.co_team_item .team_img img {
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.co_team_item h2 {
  font-size: 16px;
  text-transform: uppercase;
  color: #1a1a26;
  font-weight: 600;
  line-height: 24px;
  padding-top: 25px;
}
.co_team_item p {
  font-size: 12px;
  color: #6ed2e6;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 24px;
}
.co_team_item:hover .team_img {
  border-color: #6ed2e6;
}
.co_team_item:hover .team_img:before {
  opacity: 0.8;
}
.co_team_item:hover .team_img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.meet_team_area_two {
  padding: 96px 0px 100px;
}
.meet_team_area_two .main_title {
  padding-bottom: 75px;
}
.team_items .team_item_inner {
  text-align: center;
  -webkit-box-shadow: 0px 0px 50px -2px #e1e1e1;
  box-shadow: 0px 0px 50px -2px #e1e1e1;
}
.team_items .team_item_inner .team_img {
  position: relative;
}
.team_items .team_item_inner .team_img:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #1a1a26;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.team_items .team_item_inner .team_img img {
  max-width: 100%;
}
.team_items .team_item_inner .text {
  padding: 25px 0px;
}
.team_items .team_item_inner .text h2 {
  font-size: 16px;
  line-height: 24px;
  color: #1a1a26;
  text-transform: uppercase;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.team_items .team_item_inner .text p {
  font-size: 13px;
  line-height: 24px;
  color: #f07f84;
  text-transform: capitalize;
}
.team_items .team_item_inner:hover .team_img:before {
  opacity: 0.8;
  visibility: visible;
}
.team_items .team_item_inner:hover .text h2 {
  color: #6ed2e6;
}
.co_team_area {
  padding: 100px 0px 95px;
}
.co_team_item {
  text-align: center;
  font-family: "Roboto", sans-serif;
  margin-top: 50px;
  cursor: pointer;
}
.co_team_item .team_img {
  width: 170px;
  height: 170px;
  background: #ececec;
  border-radius: 50%;
  position: relative;
  border: 10px solid #ececec;
  overflow: hidden;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.co_team_item .team_img:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #1a1a26;
  opacity: 0;
  position: absolute;
  border-radius: 50%;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  z-index: 1;
}
.co_team_item .team_img img {
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.co_team_item h2 {
  font-size: 16px;
  text-transform: uppercase;
  color: #1a1a26;
  font-weight: 600;
  line-height: 24px;
  padding-top: 25px;
}
.co_team_item p {
  font-size: 12px;
  color: #6ed2e6;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 24px;
}
.co_team_item:hover .team_img {
  border-color: #6ed2e6;
}
.co_team_item:hover .team_img:before {
  opacity: 0.8;
}
.co_team_item:hover .team_img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.news_blog_area {
  padding: 100px 0px;
}
.news_blog_area .main_title {
  padding-bottom: 40px;
}
.n_blog_item .blog_image_slider .owl-next,
.n_blog_item .blog_image_slider .owl-prev {
  position: absolute;
  top: 15px;
  z-index: 5;
  height: 24px;
  width: 24px;
  background: #fff;
  color: #1a1a26;
  line-height: 24px;
  text-align: center;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  cursor: pointer;
}
.n_blog_item .blog_image_slider .owl-next:hover,
.n_blog_item .blog_image_slider .owl-prev:hover {
  background: #6ed2e6;
  color: #fff;
}
.n_blog_item .blog_image_slider .owl-next {
  right: 15px;
}
.n_blog_item .blog_image_slider .owl-prev {
  right: 44px;
}
.n_blog_item .n_blog_text {
  text-align: center;
}
.n_blog_item .n_blog_text h5 {
  font-size: 14px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding: 25px 0px 10px 0px;
}
.n_blog_item .n_blog_text h3 {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
  font-weight: 600;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  line-height: 28px;
}
.n_blog_item .n_blog_text h3:hover {
  color: #6ed2e6;
}
.n_blog_item .n_blog_text p {
  font-size: 14px;
  line-height: 24px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  padding: 15px 0px 30px 0px;
}
.n_blog_item .blog_image {
  position: relative;
  z-index: 2;
}
.n_blog_item .blog_image:before {
  content: "";
  position: absolute;
  background: rgba(240, 127, 132, 0.8);
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: block;
  opacity: 0;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.n_blog_item .blog_image img {
  max-width: 100%;
}
.n_blog_item .blog_image .blog_hover {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0px;
  text-align: center;
  z-index: 2;
  width: 100%;
  text-align: center;
  overflow: hidden;
}
.n_blog_item .blog_image .blog_hover a {
  margin-right: 7px;
  position: relative;
  bottom: -25px;
  opacity: 0;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.n_blog_item .blog_image .blog_hover a i {
  height: 42px;
  width: 42px;
  background: #fff;
  color: #888890;
  line-height: 42px;
  font-size: 14px;
  border-radius: 50%;
}
.n_blog_item .blog_image .blog_hover a:last-child {
  margin-right: 0px;
}
.n_blog_item .blog_image .blog_p_icon {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #fff;
  font-size: 14px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.n_blog_item:hover .blog_image:before {
  opacity: 1;
}
.n_blog_item:hover .blog_image .blog_hover a {
  bottom: 0px;
  opacity: 1;
}
.n_blog_item .blog_quote {
  text-align: center;
  background: #f07f84;
  color: #fff;
  padding: 30px 30px 40px 30px;
}
.n_blog_item .blog_quote i {
  font-size: 36px;
}
.n_blog_item .blog_quote p {
  font-size: 18px;
  line-height: 28px;
  font-family: "Roboto", serif;
  font-style: italic;
  padding: 20px 0px 12px 0px;
}
.n_blog_item .blog_quote h4 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #fff;
}
.blog_video {
  position: relative;
}
.blog_video img {
  width: 100%;
}
.blog_video .blog_hover {
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}
.blog_video .blog_hover a {
  height: 46px;
  width: 46px;
  border: 3px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #888890;
  font-size: 14px;
  line-height: 24px;
  border-radius: 50%;
  display: inline-block;
  line-height: 44px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.blog_video .blog_hover a:hover {
  background: #6ed2e6;
  border: 3px solid #6ed2e6;
  color: #fff;
}
.kl_blog_item {
  padding: 0px;
  position: relative;
}
.kl_blog_item img {
  max-width: 100%;
  z-index: -1;
}
.kl_blog_item figure {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 10px 60px 50px;
  background: rgba(0, 0, 0, 0.5);
}
.kl_blog_item figure .icon {
  width: 50px;
  height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 14px;
  line-height: 50px;
  color: #fff;
}
.kl_blog_item .blog_texts {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  width: 100%;
  font-family: "Roboto", sans-serif;
  color: #fff;
  line-height: 24px;
  padding-right: 40px;
}
.kl_blog_item .blog_texts h3 {
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 22px;
}
.kl_blog_item .blog_texts p {
  padding-bottom: 32px;
}
.kl_blog_item .blog_slider {
  border: 0px;
  border-radius: 0px;
}
.kl_blog_item .blog_slider .slides li {
  position: relative;
}
.kl_blog_item .owl-next,
.kl_blog_item .owl-prev {
  position: absolute;
  top: 15px;
  width: 30px;
  height: 30px;
  font-size: 18px;
  color: #1a1a26;
  background: #fff;
  text-align: center;
  line-height: 30px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.kl_blog_item .owl-next:hover,
.kl_blog_item .owl-prev:hover {
  background: #6ed2e6;
  color: #fff;
}
.kl_blog_item .owl-prev {
  right: 44px;
}
.kl_blog_item .owl-next {
  right: 10px;
}
.date {
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #fff;
}
.kl_address_area {
  padding: 95px 0px 65px;
}
.kl_address_area .address {
  font-family: "Roboto", sans-serif;
  min-height: 170px;
  margin-bottom: 70px;
}
.kl_address_area .address h2 {
  font-size: 18px;
  text-transform: uppercase;
  color: #1a1a26;
  padding-bottom: 10px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.kl_address_area .address h2:hover {
  color: #6ed2e6;
}
.kl_address_area .address p {
  color: #888890;
  line-height: 28px;
}
.kl_address_area .address .email {
  color: #6ed2e6;
  display: block;
}
.kl_address_area .address .phon {
  color: #888890;
}
.blog_style_three {
  background: #f8f8f8;
  padding: 100px 0px;
}
.blog_style_three .full_sec_title {
  padding-bottom: 55px;
}
.blog_style_inner .blog_style_item .blog_img {
  position: relative;
}
.blog_style_inner .blog_style_item .blog_img img {
  max-width: 100%;
}
.blog_style_inner .blog_style_item .blog_img:before {
  content: "";
  background: rgba(26, 26, 38, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.blog_style_inner .blog_style_item .blog_img .blog_date {
  position: absolute;
  width: 60px;
  height: 60px;
  background: #6ed2e6;
  color: #fff;
  text-align: center;
  right: 5px;
  top: 5px;
  opacity: 0;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.blog_style_inner .blog_style_item .blog_img .blog_date h5 {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  padding-top: 12px;
}
.blog_style_inner .blog_style_item .blog_img .blog_date h6 {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.blog_style_inner .blog_style_item .blog_style_text {
  background: #fff;
  padding: 30px;
}
.blog_style_inner .blog_style_item .blog_style_text h4 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: #1a1a26;
  line-height: 24px;
  padding-bottom: 12px;
}
.blog_style_inner .blog_style_item .blog_style_text p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  font-weight: 400;
  color: #888890;
}
.blog_style_inner .blog_style_item:hover .blog_img:before {
  opacity: 1;
}
.blog_style_inner .blog_style_item:hover .blog_date {
  opacity: 1;
}
.blog_style_inner .blog_style_item:hover .light_b {
  opacity: 1;
}
.light_b {
  height: 54px;
  width: 54px;
  color: #1a1a26;
  font-size: 16px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  text-align: center;
  line-height: 54px;
  opacity: 0;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.lt_blog_area {
  background: #f8f8f8;
  padding: 100px 0px;
}
.lt_blog_area .n_blog_item {
  padding-left: 60px;
  position: relative;
  margin-top: 48px;
  padding-bottom: 30px;
}
.lt_blog_area .n_blog_item .d_inner {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 0;
  background: #6ed2e6;
  text-align: center;
  padding-top: 12px;
}
.lt_blog_area .n_blog_item .d_inner h3 {
  line-height: 20px;
  font-size: 18px;
  display: block;
  color: #fff;
}
.lt_blog_area .n_blog_item .d_inner h3 span {
  font-size: 14px;
  font-weight: 400;
  display: block;
}
.lt_blog_area .n_blog_item .d_inner.like {
  top: 60px;
  background: #bde66e;
}
.lt_blog_area .n_blog_item .blog_image:before {
  background: rgba(26, 26, 38, 0.8);
}
.lt_blog_area .n_blog_item .blog_image .blog_hover a i {
  width: 54px;
  height: 54px;
  font-size: 16px;
  line-height: 54px;
  color: #1a1a26;
}
.lt_blog_area .n_blog_item .blog_text {
  padding-top: 25px;
}
.lt_blog_area .n_blog_item .blog_text h3 {
  font-size: 16px;
  color: #1a1a26;
  font-weight: 600;
  line-height: 22px;
  padding-bottom: 10px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.lt_blog_area .n_blog_item .blog_text p {
  color: #888890;
}
.lt_blog_area .n_blog_item .blog_text .blog_r_btn {
  width: 150px;
  margin-left: 0;
  margin-top: 35px;
  background: #1a1a26;
  color: #fff;
  font-weight: 600;
  border: 0px;
  text-align: center;
}
.lt_blog_area .n_blog_item .blog_text .blog_r_btn:hover {
  background: #6ed2e6;
}
.lt_blog_area .n_blog_item:hover .blog_text h3 {
  color: #6ed2e6;
}
.blog_main_inner {
  padding: 200px 0px 95px 0px;
}
.blog_main_inner .breadcumd_title {
  padding-bottom: 50px;
}
.pagination_area {
  margin-top: 55px;
}
.pagination_area a {
  color: #6ed2e6;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
}
.pagination_area a i {
  padding-right: 5px;
}
.blog_main_item_inner {
  margin-bottom: -50px;
  overflow: hidden;
  clear: both;
}
.blog_main_item_inner .n_blog_item {
  margin-bottom: 50px;
  min-height: 480px;
}
.port_fillter.blog_fillter {
  margin-bottom: 60px;
  padding-top: 0px;
}
.blog_main_fit .n_blog_item {
  height: 480px;
}
.r_widget_title {
  margin-bottom: 30px;
}
.r_widget_title h3 {
  font-size: 24px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  padding-bottom: 18px;
  position: relative;
  font-weight: 400;
}
.r_widget_title h3:before {
  content: "";
  width: 40px;
  background: #6ed2e6;
  height: 2px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}
.r_widget {
  margin-bottom: 55px;
}
.r_widget:last-child {
  margin-bottom: 0px;
}
.search_widget .input-group {
  display: block;
}
.search_widget .input-group input {
  display: block;
  width: 100%;
  float: none;
  border: 1px solid #e6e5e1;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border-radius: 0px;
  height: 50px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #888890;
  padding: 0px 15px;
}
.search_widget .input-group input.placeholder {
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.search_widget .input-group input:-moz-placeholder {
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.search_widget .input-group input::-moz-placeholder {
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.search_widget .input-group input::-webkit-input-placeholder {
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.search_widget .input-group input:focus {
  border-color: #6ed2e6;
}
.search_widget .input-group .input-group-btn {
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: auto;
  z-index: 3;
}
.search_widget .input-group .input-group-btn .btn-default {
  border: none;
  background: transparent;
  color: #888890;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.category_widget ul li {
  margin-bottom: 7px;
}
.category_widget ul li a {
  font-size: 14px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.category_widget ul li:last-child {
  margin-bottom: 0px;
}
.category_widget ul li:hover a {
  color: #6ed2e6;
}
.news_widget .media {
  margin-top: 20px;
}
.news_widget .media .media-left {
  padding-right: 15px;
}
.news_widget .media .media-body h4 {
  font-size: 14px;
  line-height: 18px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.news_widget .media .media-body h4:hover {
  color: #6ed2e6;
}
.news_widget .media .media-body .recent_date {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
  color: #888890;
  padding-top: 4px;
}
.news_widget .media:first-child {
  margin-top: 0px;
}
.twitter_widget .tweets_feed ul li {
  margin-bottom: 30px;
}
.twitter_widget .tweets_feed ul li .tw_content .tweet_text {
  font-size: 14px;
  color: #888890;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
}
.twitter_widget .tweets_feed ul li .tw_content .tweet_text a {
  color: #6ed2e6;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
}
.twitter_widget .tweets_feed ul li .tw_content .tweet_date {
  font-size: 14px;
  color: #888890;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
}
.twitter_widget .tweets_feed ul li .footer .tweet_time a {
  color: #6ed2e6;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
}
.twitter_widget .tweets_feed ul li:last-child {
  margin-bottom: 0px;
}
.blog_masonry_area .n_blog_item {
  min-height: auto;
}
.blog_main_list_area .blog_main_item_inner {
  margin-bottom: 0px;
}
.blog_main_list {
  width: 100%;
  margin-bottom: 40px;
}
.blog_main_list .n_blog_item {
  min-height: auto;
  margin-bottom: 0px;
}
.blog_main_list .n_blog_item .n_blog_text {
  text-align: left;
  padding-top: 55px;
}
.blog_main_list .n_blog_item .n_blog_text .blog_r_btn {
  text-align: center;
  margin: 0;
}
.blog_main_list:last-child {
  margin-bottom: 0px;
}
.single_blog_area .single_blog_title {
  padding-top: 195px;
}
.single_blog_area .single_blog_img_content .blog_video .blog_hover a {
  height: 66px;
  width: 66px;
  line-height: 64px;
  border-color: #a0a1a1;
}
.single_blog_area .single_blog_img_content .blog_video .blog_hover:hover a {
  border-color: #6ed2e6;
}
.single_blog_title {
  padding-left: 100px;
  padding-bottom: 55px;
}
.single_blog_title .blog_category {
  font-size: 18px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-bottom: 7px;
}
.single_blog_title h2 {
  font-size: 42px;
  line-height: 54px;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
  max-width: 654px;
  font-weight: bold;
}
.single_blog_title .blog_date {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
  font-weight: 400;
  padding-top: 15px;
}
.single_blog_img_content {
  padding-bottom: 52px;
}
.single_blog_img_content img {
  max-width: 100%;
}
.single_blog_img_content .blog_image_slider .owl-next,
.single_blog_img_content .blog_image_slider .owl-prev {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 5;
  height: 42px;
  width: 42px;
  background: #fff;
  color: #1a1a26;
  line-height: 42px;
  text-align: center;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  font-size: 14px;
  cursor: pointer;
}
.single_blog_img_content .blog_image_slider .owl-next:hover,
.single_blog_img_content .blog_image_slider .owl-prev:hover {
  background: #6ed2e6;
  color: #fff;
}
.single_blog_img_content .blog_image_slider .owl-next {
  right: 0px;
}
.single_blog_img_content .blog_image_slider .owl-prev {
  left: 0px;
}
.single_blog_img_content .single_blog_content {
  padding: 60px 100px 0px 100px;
}
.single_blog_img_content .single_blog_content img {
  max-width: 100%;
}
.single_blog_img_content .single_blog_content p {
  font-size: 14px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  padding-bottom: 25px;
}
.single_blog_img_content .single_blog_content p:last-child {
  padding-bottom: 0px;
}
.single_blog_img_content .single_blog_content blockquote {
  margin-left: 100px;
  padding-left: 48px;
  border-left: 3px solid #6ed2e6;
  max-width: 770px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 10px;
  margin-bottom: 35px;
}
.single_blog_img_content .single_blog_content blockquote p {
  padding-bottom: 0px;
  font-size: 18px;
  line-height: 32px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.single_blog_single_img {
  padding-bottom: 52px;
}
.single_blog_single_img img {
  max-width: 100%;
  margin-bottom: 30px;
}
.single_blog_single_img .s_content {
  padding-top: 15px;
  overflow: hidden;
  clear: both;
}
.single_blog_single_img .s_content p {
  padding: 0px 100px 0px 115px;
  margin-bottom: 22px;
}
.single_blog_single_img .s_content p:last-child {
  margin-bottom: 0px;
}
.single_blog_category {
  border-top: 1px solid #e6e5e1;
  border-bottom: 1px solid #e6e5e1;
  overflow: hidden;
  clear: both;
}
.single_blog_category .pull-left {
  padding-left: 100px;
}
.single_blog_category .pull-left a {
  font-size: 14px;
  line-height: 68px;
  display: inline-block;
  color: #888890;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  margin-right: 24px;
  font-style: italic;
}
.single_blog_category .pull-left a:hover {
  color: #1a1a26;
}
.single_blog_category .pull-left a:last-child {
  margin-right: 0px;
}
.single_blog_category .pull-right {
  padding-right: 100px;
}
.single_blog_category .pull-right h4 {
  display: inline-block;
  font-size: 16px;
  line-height: 68px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
  padding-right: 15px;
}
.single_blog_category .pull-right ul {
  display: inline-block;
}
.single_blog_category .pull-right ul li {
  display: inline-block;
  margin-right: 20px;
}
.single_blog_category .pull-right ul li a {
  color: #1a1a26;
  font-size: 16px;
  line-height: 68px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.single_blog_category .pull-right ul li:last-child {
  margin-right: 0px;
}
.single_blog_category .pull-right ul li:hover a {
  color: #888890;
}
.single_new_prev_post {
  padding: 30px 0px;
  clear: both;
  overflow: hidden;
  border-bottom: 1px solid #e6e5e1;
}
.single_new_prev_post .pull-left {
  padding-left: 100px;
}
.single_new_prev_post .pull-left .media .media-left {
  padding-right: 20px;
}
.single_new_prev_post .pull-left .media .media-body {
  width: auto;
  vertical-align: middle;
}
.single_new_prev_post .pull-left .media .media-body h5 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
}
.single_new_prev_post .pull-left .media .media-body h4 {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #1a1a26;
  padding-top: 10px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.single_new_prev_post .pull-left .media .media-body h4:hover {
  color: #6ed2e6;
}
.single_new_prev_post .pull-right {
  padding-right: 100px;
}
.single_new_prev_post .pull-right .media .media-right {
  padding-left: 20px;
}
.single_new_prev_post .pull-right .media .media-body {
  width: auto;
  vertical-align: middle;
  text-align: right;
}
.single_new_prev_post .pull-right .media .media-body h5 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
}
.single_new_prev_post .pull-right .media .media-body h4 {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #1a1a26;
  padding-top: 10px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.single_new_prev_post .pull-right .media .media-body h4:hover {
  color: #6ed2e6;
}
.like_post_area {
  padding: 100px 0px;
}
.like_post_area .sec_title3 {
  text-align: center;
  margin-bottom: 40px;
}
.like_post_area .sec_title3 h2:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.single_blog_r_sidebar .single_blog_title {
  text-align: center;
  padding-left: 0px;
}
.single_blog_r_sidebar .single_blog_title h2 {
  margin: auto;
}
.single_blog_r_sidebar .single_blog_image {
  text-align: center;
  max-width: 100%;
}
.single_blog_r_sidebar .single_blog_image .blog_video .blog_hover a {
  height: 66px;
  width: 66px;
  line-height: 64px;
  border-color: #a0a1a1;
}
.single_blog_r_sidebar .single_blog_image .blog_video .blog_hover:hover a {
  border-color: #6ed2e6;
}
.single_blog_r_sidebar .single_blog_img_content .single_blog_content {
  padding: 0px;
}
.single_blog_r_sidebar
  .single_blog_img_content
  .single_blog_content
  blockquote {
  margin-left: 0px;
}
.single_blog_r_sidebar .single_blog_single_img p {
  padding-left: 0px;
  padding-right: 0px;
}
.single_blog_r_sidebar .single_blog_category .pull-left {
  padding-left: 0px;
}
.single_blog_r_sidebar .single_blog_category .pull-right {
  padding-right: 0px;
}
.single_blog_r_sidebar .single_new_prev_post .pull-left {
  padding-left: 0px;
}
.single_blog_r_sidebar .single_new_prev_post .pull-right {
  padding-right: 0px;
}
.single_blog_r_sidebar .single_r_sidebar_inner {
  margin-top: 60px;
}
.comment_area {
  background: #f8f8f8;
  padding: 100px 0px;
}
.comment_title {
  padding-bottom: 50px;
}
.comment_title h3 {
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  line-height: 40px;
}
.comment_title p {
  font-size: 14px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-top: 15px;
}
.comment_list .media {
  border-bottom: 1px solid #e6e5e1;
  padding: 40px 0px 35px 0px;
  margin-top: 0px;
}
.comment_list .media .media-left {
  padding-right: 60px;
}
.comment_list .media .media-body h4 {
  font-size: 14px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}
.comment_list .media .media-body h5 {
  font-size: 12px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding: 10px 0px 15px 0px;
}
.comment_list .media .media-body p {
  font-size: 14px;
  line-height: 24px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  padding-bottom: 15px;
}
.comment_list .media .media-body .comment_reply {
  font-size: 14px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.comment_list .media .media-body .comment_reply:hover {
  color: #1a1a26;
}
.comment_form_area {
  padding-top: 60px;
}
.comment_form_area .office_contact_form .form-group {
  margin-bottom: 30px;
}
.comment_form_area .office_contact_form .form-group input {
  padding: 0px 15px;
  border: 1px solid #e6e5e1;
  position: relative;
}
.comment_form_area .office_contact_form .form-group textarea {
  padding: 18px 20px;
  border: 1px solid #e6e5e1;
}
.comment_form_area .office_contact_form .form-group .submit_dark {
  width: 210px;
  margin: 10px 0px 0px 0px;
}
.comment_form_area .office_contact_form .form-group:last-child {
  margin-bottom: 0px;
}
.p_star {
  display: inline-block;
  position: relative;
}
.p_star input {
  background: #fff;
}
.p_star input:valid + .placeholder {
  display: none;
}
.p_star .placeholder {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 30px;
  z-index: 1;
}
.p_star .placeholder:before {
  content: attr(data-placeholder);
  color: #999;
}
.p_star .placeholder:after {
  content: " *";
  color: tomato;
}
.ro_skill_area {
  background: #f8f8f8;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 850px;
}
.skill_left_side {
  width: 50%;
  float: left;
  overflow: hidden;
}
.skill_left_side .inner_sec {
  width: 50%;
  float: left;
  padding: 94px 60px 256px;
  position: relative;
  z-index: 1;
}
.skill_left_side .inner_sec:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
}
.skill_left_side .skill_inner_sec {
  /* background: url("../img/skill/skill-bg.jpg") no-repeat scroll center 0; */
  background-size: cover;
}
.skill_left_side .skill_inner_sec:before {
  background: rgba(61, 61, 61, 0.4);
}
.skill_left_side .struck_inner_sec {
  /* background: url("../img/skill/str-bg.jpg") no-repeat scroll center 0; */
  background-size: cover;
  padding-bottom: 183px;
}
.skill_left_side .struck_inner_sec:before {
  background: rgba(0, 0, 0, 0.65);
}
.skill_left_side .struck_inner_sec .tab-accordion {
  padding-top: 35px;
}
.skill_left_side .struck_inner_sec .tab-accordion .panel {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  border-radius: 0px;
  border-color: #fff;
  opacity: 1;
}
.skill_left_side .struck_inner_sec .tab-accordion .panel .panel-heading {
  padding: 0px;
  background: transparent;
}
.skill_left_side
  .struck_inner_sec
  .tab-accordion
  .panel
  .panel-heading
  .panel-title
  a {
  display: block;
  font: 600 14px/50px "Roboto", sans-serif;
  color: #fff;
  padding: 0px 15px;
  position: relative;
}
.skill_left_side
  .struck_inner_sec
  .tab-accordion
  .panel
  .panel-heading
  .panel-title
  a
  i {
  position: absolute;
  right: 15px;
  line-height: 50px;
  font-size: 14px;
}
.skill_left_side
  .struck_inner_sec
  .tab-accordion
  .panel
  .panel-heading
  .panel-title
  a
  i
  + i {
  display: none;
}
.skill_left_side
  .struck_inner_sec
  .tab-accordion
  .panel
  .panel-heading
  .panel-title
  a.collapsed
  i {
  display: none;
}
.skill_left_side
  .struck_inner_sec
  .tab-accordion
  .panel
  .panel-heading
  .panel-title
  a.collapsed
  i
  + i {
  display: inline-block;
}
.skill_left_side
  .struck_inner_sec
  .tab-accordion
  .panel
  .panel-collapse
  .panel-body {
  border: 0px;
  padding-top: 5px;
  color: #fff;
  font: 400 14px/24px "Roboto", sans-serif;
}
.skill_left_side .struck_inner_sec .tab-accordion .panel + .panel {
  margin-top: 15px;
}
.skill_right_side {
  width: 50%;
  float: right;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.skill_right_side .feature_inner {
  padding-left: 60px;
  max-width: 810px;
  margin-right: auto;
}
.skill_right_side .feature_inner .main_title {
  text-align: left;
}
.skill_right_side .feature_inner .main_title h2:before {
  left: 0;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  background: #f07f84;
}
.skill_right_side .feature_inner .ro-features {
  padding-top: 40px;
  padding-bottom: 26px;
}
.skill_right_side .feature_inner .ro-features .features_item {
  max-width: 50%;
  float: left;
  margin-bottom: 45px;
  cursor: pointer;
}
.skill_right_side .feature_inner .ro-features .features_item .media-left {
  padding-right: 30px;
}
.skill_right_side .feature_inner .ro-features .features_item .media-left i {
  width: 70px;
  height: 70px;
  text-align: center;
  font-size: 25px;
  line-height: 70px;
  color: #1c1f2c;
  background: #75e0f5;
  -webkit-box-shadow: 2px 2px 0px 0px #dbdbe9;
  box-shadow: 2px 2px 0px 0px #dbdbe9;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  display: block;
}
.skill_right_side .feature_inner .ro-features .features_item .media-body h4 {
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
  padding-bottom: 10px;
}
.skill_right_side .feature_inner .ro-features .features_item .media-body p {
  font: 400 14px/24px "Roboto", sans-serif;
  color: #888890;
}
.skill_right_side .feature_inner .ro-features .features_item:nth-child(2n) {
  float: right;
  padding-left: 20px;
}
.skill_right_side .feature_inner .ro-features .features_item + .features_item {
  margin-top: 0px;
}
.skill_right_side
  .feature_inner
  .ro-features
  .features_item:hover
  .media-left
  i {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.skill_item {
  color: #fff;
  padding-top: 15px;
}
.skill_item .skill-name {
  font: 500 16px/40px "Roboto", sans-serif;
  color: #fff;
  display: inline-block;
}
.skill_item .skill-coun {
  font: 400 18px/40px "Roboto", sans-serif;
  display: inline-block;
  float: right;
}
.skill_item .bt-skill .bt-skillholder {
  width: 100%;
  display: block;
  height: 10px;
  border-radius: 40px;
  background: #fff;
}
.skill_item .bt-skill .bt-skillholder .bt-skillbar {
  height: 10px;
  background-image: -moz-linear-gradient(180deg, #a1c4fd 0%, #c2e9fb 100%);
  background-image: -webkit-linear-gradient(180deg, #a1c4fd 0%, #c2e9fb 100%);
  background-image: -ms-linear-gradient(180deg, #a1c4fd 0%, #c2e9fb 100%);
  float: left;
  border-radius: 40px;
}
.dark_skill .skill_item .skill-name {
  color: #1a1a26;
}
.dark_skill .skill_item .skill-coun {
  color: #888890;
}
.kl_agency_area .display-table {
  display: table;
}
.kl_agency_area .display-table .col_height {
  display: table-row;
}
.kl_agency_area .display-table .agency-left-side,
.kl_agency_area .display-table .agency-right-side {
  width: 50%;
  display: table-cell;
}
.agency-left-side {
  background: #f8f8f8;
  padding: 83px 60px 180px;
  vertical-align: top;
}
.agency-left-side .sec_title h2 {
  text-transform: uppercase;
  font-size: 32px;
}
.agency_content .tab_fillter {
  border-color: #dadedf;
  margin: 45px 0px 25px;
}
.agency_content .tab_fillter li {
  margin: 0px;
}
.agency_content .tab_fillter li a {
  font: 500 16px/28px "Roboto", sans-serif;
  color: #1a1a26;
  padding: 0px;
  border-radius: 0px;
  border: 0px;
  margin: 0;
  text-transform: capitalize;
  position: relative;
}
.agency_content .tab_fillter li a:before {
  content: "";
  width: 0;
  height: 3px;
  bottom: -1px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: transparent;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.agency_content .tab_fillter li a:focus,
.agency_content .tab_fillter li a:hover {
  background: transparent;
}
.agency_content .tab_fillter li a:focus:before,
.agency_content .tab_fillter li a:hover:before {
  content: "";
  width: 100%;
  background: #6ed2e6;
}
.agency_content .tab_fillter li.active a {
  background: transparent;
  border: 0px;
  color: #6ed2e6;
}
.agency_content .tab_fillter li.active a:before {
  content: "";
  width: 100%;
  background: #6ed2e6;
}
.agency_content .tab_fillter li + li {
  padding-left: 60px;
}
.agency_content .agency_tab p {
  color: #888890;
  line-height: 24px;
}
.agency_content .agency_tab .tab_item {
  max-width: 33%;
  float: left;
  padding: 50px 0px 0px;
}
.agency_content .agency_tab .tab_item .tab-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #888890;
  line-height: 100px;
  text-align: center;
}
.agency_content .agency_tab .tab_item h2 {
  font: 500 16px/30px "Roboto", sans-serif;
  color: #1e1e1f;
  padding: 23px 0px 5px;
}
.agency_content .agency_tab .tab_item + .tab_item {
  margin-left: 70px;
}
.agency-right-side {
  /* background: url("../img/service/single-image-slider.jpg") no-repeat center 0; */
  background-size: cover;
  z-index: 1;
  position: relative;
}
.agency-right-side:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
.agency-right-side .owl-next,
.agency-right-side .owl-prev {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}
.agency-right-side .owl-next i,
.agency-right-side .owl-prev i {
  width: 42px;
  height: 42px;
  font-size: 18px;
  line-height: 40px;
  background: #fff;
  text-align: center;
  cursor: pointer;
}
.agency-right-side .owl-next i:hover,
.agency-right-side .owl-prev i:hover {
  background: #6ed2e6;
  color: #fff;
}
.agency-right-side .owl-prev {
  left: 0;
}
.agency-right-side .owl-next {
  right: 0;
}
.passion_slider {
  max-width: 535px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.passion_slider .sec_title {
  margin-bottom: 25px;
}
.passion_slider .sec_title h2:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.passion_slider .l_btn {
  margin-top: 50px;
}
.funfact_item {
  padding: 0px;
  position: relative;
  z-index: 1;
  width: calc(100% / 5);
  float: left;
}
.funfact_item:before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 0;
}
.funfact_item img {
  max-width: 100%;
}
.fact_content {
  position: absolute;
  top: 50%;
  color: #fff;
  text-align: center;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}
.fact_content h3 {
  font-size: 42px;
  line-height: 50px;
  font-weight: 500;
}
.fact_content h2 {
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 400;
  padding-bottom: 13px;
}
.kl_mul_fact_area {
  background: #1a1a26;
  padding: 55px 0px 60px;
}
.kl_mul_fact_area .funfact_items .fact_content {
  position: relative;
  left: auto;
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
}
.ro_testimonial_area {
  overflow: hidden;
}
.testimonial_left_inner {
  width: 50%;
  float: left;
  background: #f8f8f8;
  padding: 100px 60px;
}
.testimonial_left_inner .testimonial_slider .item {
  background: #fff;
  text-align: center;
  padding: 60px 50px 55px;
  margin-top: 80px;
  position: relative;
  -webkit-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.95);
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.95);
}
.testimonial_left_inner .testimonial_slider .item img {
  width: auto;
  top: -40px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.testimonial_left_inner .testimonial_slider .item p {
  font: 400 18px/30px "Roboto", serif;
  color: #888890;
  font-style: italic;
}
.testimonial_left_inner .testimonial_slider .item .clients_nam {
  font-family: "Roboto", sans-serif;
  padding-top: 25px;
}
.testimonial_left_inner .testimonial_slider .item .clients_nam h2 {
  font-size: 18px;
  line-height: 32px;
  color: #1a1a26;
}
.testimonial_left_inner .testimonial_slider .item .clients_nam h6 {
  color: #6ed2e6;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}
.testimonial_left_inner .testimonial_slider .owl-nav {
  position: absolute;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -16px;
  left: 50%;
}
.testimonial_left_inner .testimonial_slider .owl-next,
.testimonial_left_inner .testimonial_slider .owl-prev {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 15px;
  color: #fff;
  line-height: 35px;
  background: #888890;
  display: inline-block;
  margin: 0px 3px;
  text-align: center;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.testimonial_left_inner .testimonial_slider .owl-next:hover,
.testimonial_left_inner .testimonial_slider .owl-prev:hover {
  background: #6ed2e6;
}
.testimonial_left_inner .clients_logo {
  padding-top: 96px;
}
.testimonial_left_inner .clients_logo .logo_items {
  text-align: center;
}
.testimonial_right_inner {
  width: 50%;
  float: right;
  /* background: url("../img/skill/test-bg.jpg") no-repeat scroll center 0; */
  height: 100%;
  background-size: cover;
  min-height: 820px;
  color: #fff;
  position: relative;
  z-index: 1;
}
.testimonial_right_inner:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.45);
  z-index: -1;
}
.testimonial_right_inner .testimonial-content {
  text-align: center;
  padding: 0px 60px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.testimonial_right_inner .testimonial-content .main_title h5 {
  color: #fff;
  font-size: 24px;
  font-style: italic;
}
.testimonial_right_inner .testimonial-content .main_title h2 {
  color: #fff;
}
.testimonial_right_inner .testimonial-content .main_title h2:before {
  background: #fff;
}
.testimonial_right_inner .testimonial-content p {
  padding-top: 35px;
  line-height: 24px;
}
.testimonial_right_inner .testimonial-content .c_btn {
  width: 230px;
  margin-top: 50px;
}
.partners_right,
.testimonial_left {
  width: 50%;
  float: left;
  min-height: 860px;
}
.testimonial_left {
  text-align: center;
  /* background: url("../img/service/single-image-slider.jpg") no-repeat center 0; */
  background-size: cover;
  position: relative;
  z-index: 1;
  padding-top: 220px;
}
.testimonial_left:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
.testimonial_left .testimonial_text {
  max-width: 640px;
  border: 5px solid #6ed2e6;
  margin: 0px auto;
  padding: 47px;
  position: relative;
}
.testimonial_left .testimonial_text:before {
  content: "";
  width: 1px;
  height: 50px;
  background: #fff;
  position: absolute;
  left: 50%;
  bottom: -27px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.testimonial_left .testimonial_text p {
  font: 400 18px/32px "Roboto", serif;
  font-style: italic;
  color: #fff;
}
.testimonial_left .test_img {
  color: #fff;
  padding-top: 40px;
}
.testimonial_left .test_img img {
  width: auto;
  display: inline-block;
}
.testimonial_left .test_img h2 {
  font: 500 18px/32px "Roboto", sans-serif;
  padding-top: 15px;
}
.testimonial_left .test_img p {
  font: 400 14px/20px "Roboto", sans-serif;
}
.partners_right {
  background: #f8f8f8;
  padding: 85px 60px 0px;
}
.partners_right .partner_slider {
  margin-top: 55px;
}
.partners_right .partner_slider .partner_item {
  width: calc(100% / 3);
  float: left;
  text-align: center;
  padding: 35px 0px;
  border: 1px solid #dadedf;
  border-top: none;
  border-left: none;
}
.partners_right .partner_slider .partner_item img {
  width: auto;
  display: inline-block;
}
.partners_right .partner_slider .partner_item:nth-child(3),
.partners_right .partner_slider .partner_item:nth-child(6),
.partners_right .partner_slider .partner_item:nth-child(9) {
  border-right: 0px;
}
.partners_right .partner_slider .partner_item:nth-last-child(-n + 3) {
  border-bottom: none;
  border-left: 0px;
}
.partners_right .owl-dots {
  text-align: center;
  margin-top: 55px;
}
.partners_right .owl-dots .owl-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #c0c0c4;
  display: inline-block;
  margin: 5px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.partners_right .owl-dots .owl-dot.active {
  background: #6ed2e6;
}
.partner_slider {
  margin-top: 55px;
}
.partner_slider .partner_item {
  width: calc(100% / 3);
  float: left;
  text-align: center;
  padding: 35px 0px;
  border: 1px solid #dadedf;
  border-top: none;
  border-left: none;
}
.partner_slider .partner_item img {
  width: auto;
  display: inline-block;
}
.partner_slider .partner_item:nth-child(3),
.partner_slider .partner_item:nth-child(6),
.partner_slider .partner_item:nth-child(9) {
  border-right: 0px;
}
.partner_slider .partner_item:nth-last-child(-n + 3) {
  border-bottom: none;
  border-left: 0px;
}
.customer_ttm_area {
  padding: 100px 0px 85px 0px;
}
.customer_ttm_area .full_sec_title {
  padding-bottom: 80px;
}
.saying_ttm_slider .item {
  padding: 15px;
}
.saying_ttm_slider .item .media .media-left {
  padding-right: 20px;
}
.saying_ttm_slider .item .media .media-left img {
  width: auto;
}
.saying_ttm_slider .item .media .media-body {
  vertical-align: middle;
}
.saying_ttm_slider .item .media .media-body h4 {
  font-size: 18px;
  color: #424242;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  padding-bottom: 12px;
}
.saying_ttm_slider .item .media .media-body h5 {
  font-size: 14px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.saying_ttm_slider .item p {
  margin-top: 15px;
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
}
.saying_ttm_slider .item p img {
  width: auto;
  display: inline-block;
  padding-right: 20px;
  vertical-align: top;
}
.saying_ttm_slider .item .ttm_item {
  border: 1px solid #ececec;
  border-radius: 10px;
  padding: 30px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.saying_ttm_slider .item .ttm_item:hover {
  -webkit-box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.1);
}
.clients_slider2_area {
  background: #f9f9f9;
}
.clients_slider2_area .clients_slider2_inner {
  max-width: 1030px;
  position: relative;
  margin: auto;
}
.clients_slider2_area .clients_slider2_inner .owl-next,
.clients_slider2_area .clients_slider2_inner .owl-prev {
  position: absolute;
  z-index: 5;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  border-radius: 505;
  background: #fff;
  color: #1a1a26;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  line-height: 42px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  -webkit-box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.clients_slider2_area .clients_slider2_inner .owl-next:hover,
.clients_slider2_area .clients_slider2_inner .owl-prev:hover {
  background: #6ed2e6;
  color: #fff;
}
.clients_slider2_area .clients_slider2_inner .owl-prev {
  left: -70px;
}
.clients_slider2_area .clients_slider2_inner .owl-next {
  right: -70px;
}
.clients_slider2_inner .item {
  line-height: 150px;
  text-align: center;
}
.clients_slider2_inner .item img {
  max-width: 100%;
  width: auto;
  display: inline-block;
}
.partner_logo_area {
  /* background: url("../public/img/work_img/partner-bg.jpg") no-repeat scroll center 0; */
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 150px 0px;
}
.partner_logo_area:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
}
.partner_logos {
  max-width: 970px;
  margin: 0 auto;
  border: 10px solid #f07f84;
  padding: 65px 70px;
  overflow: hidden;
}
.partner_logos .pr_logo_item {
  width: calc(100% / 4);
  float: left;
  text-align: center;
  padding: 50px 0px;
}
.partner_logos .pr_logo_item img {
  max-width: 100%;
  opacity: 0.6;
  -webkit-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.partner_logos .pr_logo_item:hover img {
  opacity: 1;
}
.partner_logo_area_two {
  padding-bottom: 100px;
}
.partner_logo_area_two .partner_logos {
  border-color: #f5f6f6;
}
.services_item {
  padding: 0px;
  position: relative;
  z-index: 1;
}
.services_item:before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(126, 87, 194, 0.8);
  z-index: 0;
}
.services_item .services_content {
  position: absolute;
  bottom: 100px;
  color: #fff;
  padding: 0px 50px;
}
.services_item .services_content h5 {
  font: 500 18px/50px "Roboto", sans-serif;
}
.services_item .services_content p {
  line-height: 24px;
  padding: 8px 0px 50px;
}
.services_item:nth-child(2):before {
  background-color: rgba(26, 26, 38, 0.8);
}
.services_item:nth-child(3):before {
  background-color: rgba(240, 127, 132, 0.8);
}
.services_item:nth-child(4):before {
  background-color: rgba(63, 81, 181, 0.8);
}
.b_service_area {
  overflow: hidden;
  clear: both;
}
.b_service_area .b_service_item {
  width: calc(100% / 4);
  float: left;
  overflow: hidden;
  background: #6ed2e6;
  text-align: center;
  padding: 90px 0px;
}
.b_service_area .b_service_item:nth-child(2n) {
  background: #75e0f5;
}
.b_service_area .b_service_item .b_service_inner {
  max-width: 320px;
  margin: auto;
}
.b_service_area .b_service_item .b_service_inner img {
  max-width: 100%;
}
.b_service_area .b_service_item .b_service_inner h4 {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  padding: 30px 0px 15px 0px;
}
.b_service_area .b_service_item .b_service_inner p {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 15px;
}
.service_three_area {
  background: #f8f8f8;
  padding: 100px 0px;
}
.service_three_item {
  font-family: "Roboto", sans-serif;
  text-align: center;
  padding: 47px 48px 90px;
  -webkit-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
  margin-top: 30px;
}
.service_three_item i {
  font-size: 50px;
  color: #888890;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.service_three_item img {
  display: none;
  margin-bottom: 5px;
}
.service_three_item img + img {
  display: inline-block;
}
.service_three_item h2 {
  font-size: 24px;
  line-height: 50px;
  color: #1a1a26;
  text-transform: uppercase;
  padding-bottom: 15px;
}
.service_three_item p {
  font-size: 16px;
  line-height: 26px;
  color: #888890;
}
.service_three_item a {
  font: 16px;
  line-height: 26px;
  color: #f07f84;
  padding-top: 15px;
  display: inline-block;
}
.service_three_item:hover {
  background: #fff;
  -webkit-box-shadow: 0px 0px 50px -20px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 50px -20px rgba(0, 0, 0, 0.4);
}
.service_three_item:hover i {
  color: #f07f84;
}
.service_three_item:hover img {
  display: inline-block;
}
.service_three_item:hover img + img {
  display: none;
}
.office_area {
  padding: 96px 0px 88px;
}
.office_item .office_img {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 30px;
}
.office_item .office_img:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  z-index: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.office_item .office_img img {
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  z-index: -1;
}
.office_item .office_img .submit_dark {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #1a1a26;
  background: #6ed2e6;
  opacity: 0;
  -webkit-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.office_item .office_img:hover:before {
  opacity: 0.8;
  z-index: 1;
}
.office_item .office_img:hover img {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}
.office_item .office_img:hover .submit_dark {
  opacity: 1;
  z-index: 2;
}
.office_address {
  margin-top: 35px;
}
.office_address .office_address_item {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
}
.office_address .office_address_item h4 {
  font-weight: 700;
  font-size: 14px;
  line-height: 38px;
}
.office_address .office_address_item h4 a,
.office_address .office_address_item h4 span {
  font-weight: 400;
  color: #888890;
}
.office_address .office_address_item a {
  color: #6ed2e6;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.office_address .office_address_item a:hover {
  color: #1a1a26;
}
.head_title {
  font: 500 16px/24px "Roboto", sans-serif;
  color: #1a1a26;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 16px;
  margin-bottom: 15px;
}
.head_title:before {
  content: "";
  width: 50px;
  height: 2px;
  background: #6ed2e6;
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
}
.map_area {
  position: relative;
}
.map_area .mapBox {
  min-height: 850px;
}
.map_area .office_word_area {
  position: absolute;
  left: 0px;
  max-width: 960px;
  width: 100%;
  top: 0px;
}
.map_area .office_word_area .office_text {
  width: 50%;
  overflow: hidden;
  background: #f8f8f8;
  min-height: 850px;
  padding: 0px 55px;
}
.map_area .office_word_area .office_text .sec_b_title {
  padding-bottom: 35px;
  padding-top: 100px;
}
.map_area .office_word_area .contact_form {
  width: 50%;
  overflow: hidden;
  min-height: 850px;
  /* background: url("../img/contact-bg.jpg") no-repeat scroll center center; */
  background-size: cover;
  position: relative;
  z-index: 2;
  padding: 0px 60px;
}
.map_area .office_word_area .contact_form:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: -1;
  left: 0px;
}
.map_area .office_word_area .contact_form .sec_title {
  padding-bottom: 35px;
  padding-top: 100px;
}
.office_text_inner .office_text_item {
  margin-top: 50px;
}
.office_text_inner .office_text_item h4 {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
  text-transform: uppercase;
  padding-bottom: 15px;
}
.office_text_inner .office_text_item p {
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.office_text_inner .office_text_item:first-child {
  margin-top: 0px;
}
.rewuest_contact .form-group input {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 0px;
  height: 50px;
  padding: 0px 15px;
  outline: none;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rewuest_contact .form-group input.placeholder {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.rewuest_contact .form-group input:-moz-placeholder {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.rewuest_contact .form-group input::-moz-placeholder {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.rewuest_contact .form-group input::-webkit-input-placeholder {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.rewuest_contact .form-group input:focus {
  border-color: #6ed2e6;
}
.rewuest_contact .form-group label {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding-top: 5px;
}
.rewuest_contact .form-group .bootstrap-select {
  width: 100% !important;
}
.rewuest_contact .form-group .bootstrap-select .dropdown-toggle {
  padding: 0px 15px;
  background: transparent;
  border-radius: 0px;
  border: 1px solid #fff;
  height: 50px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.rewuest_contact
  .form-group
  .bootstrap-select
  .dropdown-toggle
  .bs-caret:before {
  content: "\f107";
  font: normal normal normal 14px/1 FontAwesome;
  position: relative;
  right: 15px;
  top: 2px;
}
.rewuest_contact
  .form-group
  .bootstrap-select
  .dropdown-toggle
  .bs-caret
  .caret {
  display: none;
}
.rewuest_contact .form-group .bootstrap-select .dropdown-menu {
  margin-top: 5px;
  padding: 0px;
  border: none;
  border-radius: 0px;
}
.rewuest_contact .form-group .bootstrap-select .dropdown-menu ul {
  margin-top: 0px;
}
.rewuest_contact .form-group .bootstrap-select .dropdown-menu ul li a {
  padding: 10px 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #1a1a26;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  background: transparent;
}
.rewuest_contact .form-group .bootstrap-select .dropdown-menu ul li:hover a {
  color: #fff;
  background: #6ed2e6;
}
.rewuest_contact .form-group:last-child {
  margin-top: 25px;
}
.req_contact_area {
  padding: 100px 0px;
  position: relative;
}
.req_contact_area:before {
  content: "";
  /* background: url("../img/about/about-pattern.jpg") no-repeat center left; */
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
}
.req_map .req_map {
  min-height: 518px;
  border: 10px solid #fff;
  max-width: 470px;
  -webkit-box-shadow: 0px 0px 50px -20px rgba(30, 30, 31, 0.5);
  -moz-box-shadow: 0px 0px 50px -20px rgba(30, 30, 31, 0.5);
  box-shadow: 0px 0px 50px -20px rgba(30, 30, 31, 0.5);
}
.req_contact_form .sec_b_title {
  padding-left: 15px;
  padding-bottom: 30px;
}
.req_contact_inner .form-group input {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ececec;
  border-radius: 0px;
  outline: none;
  height: 50px;
  padding: 0px 15px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.req_contact_inner .form-group input.placeholder {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
}
.req_contact_inner .form-group input:-moz-placeholder {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
}
.req_contact_inner .form-group input::-moz-placeholder {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
}
.req_contact_inner .form-group input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
}
.req_contact_inner .form-group input:focus {
  border: 1px solid #6ed2e6;
}
.req_contact_inner .form-group label {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #888890;
  text-align: left;
  display: block;
  padding-top: 5px;
  height: 35px;
}
.req_contact_inner .form-group .bootstrap-select {
  width: 100% !important;
}
.req_contact_inner .form-group .bootstrap-select .dropdown-toggle {
  padding: 0px 15px;
  background: transparent;
  border-radius: 0px;
  border: 1px solid #ececec;
  height: 50px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.req_contact_inner
  .form-group
  .bootstrap-select
  .dropdown-toggle
  .bs-caret:before {
  content: "\f107";
  font: normal normal normal 14px/1 FontAwesome;
  position: relative;
  right: 15px;
  top: 2px;
}
.req_contact_inner
  .form-group
  .bootstrap-select
  .dropdown-toggle
  .bs-caret
  .caret {
  display: none;
}
.req_contact_inner .form-group .bootstrap-select .dropdown-menu {
  margin-top: 5px;
  padding: 0px;
  border: none;
  border-radius: 0px;
}
.req_contact_inner .form-group .bootstrap-select .dropdown-menu ul {
  margin-top: 0px;
}
.req_contact_inner .form-group .bootstrap-select .dropdown-menu ul li a {
  padding: 10px 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #1a1a26;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  background: transparent;
}
.req_contact_inner .form-group .bootstrap-select .dropdown-menu ul li:hover a {
  color: #fff;
  background: #6ed2e6;
}
.req_contact_inner .form-group .submit_dark {
  margin-top: 45px;
}
.kl_request_form_area {
  /* background: url("../img/contact-bg2.jpg") no-repeat scroll center 0; */
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 82px 0px 100px;
}
.kl_request_form_area:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  background: rgba(0, 0, 0, 0.8);
}
.kl_request_form_area .sec_title {
  text-align: center;
  margin-bottom: 40px;
}
.kl_request_form_area .sec_title h2 {
  font-size: 32px;
}
.kl_request_form_area .sec_title h2:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.kl_request_form_area .req_contact_inner {
  max-width: 470px;
  margin: 0 auto;
}
.kl_request_form_area .req_contact_inner .form-group {
  text-align: center;
}
.kl_request_form_area
  .req_contact_inner
  .form-group
  .bootstrap-select
  .dropdown-toggle {
  background: #fff;
}
.kl_request_form_area .req_contact_inner .form-group .submit_dark {
  margin-top: 10px;
  background: #6ed2e6;
}
.kl_request_form_area .req_contact_inner .form-group .submit_dark:hover {
  background: #1e1e1f;
}
.searchForm {
  background: #1a1a26;
  height: 0px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  visibility: hidden;
  z-index: 15;
  position: relative;
}
.searchForm.full_page_search {
  height: 100%;
  opacity: 0;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 30;
}
.searchForm.full_page_search .input-group {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.searchForm .input-group .input-group-addon {
  background: transparent;
  border: none;
  color: #fff;
  padding: 6px 20px;
  font-size: 18px;
  cursor: pointer;
  -webkit-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
  opacity: 0;
}
.searchForm .input-group .input-group-addon:hover {
  color: #6ed2e6;
}
.searchForm .input-group input {
  background: transparent;
  border-radius: 0px;
  border: none;
  height: 80px;
  padding: 0px 15px;
  text-align: center;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  opacity: 0;
}
.searchForm .input-group input.placeholder {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.searchForm .input-group input:-moz-placeholder {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.searchForm .input-group input::-moz-placeholder {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.searchForm .input-group input::-webkit-input-placeholder {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.searchForm.show {
  height: 80px;
  visibility: visible;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.searchForm.show.full_page_search {
  height: 100%;
  opacity: 1;
}
.searchForm.show .input-group .input-group-addon {
  opacity: 1;
}
.searchForm.show .input-group input {
  opacity: 1;
}
.fixed {
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.fixed .searchForm {
  position: fixed;
  width: 100%;
  top: 0px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.work_contact_inner .full_left_sec_title {
  padding-bottom: 60px;
}
.work_contact_inner .rewuest_contact .form-group input {
  border-color: #ececec;
  color: #888890;
}
.work_contact_inner .rewuest_contact .form-group input.placeholder {
  color: #888890;
}
.work_contact_inner .rewuest_contact .form-group input:-moz-placeholder {
  color: #888890;
}
.work_contact_inner .rewuest_contact .form-group input::-moz-placeholder {
  color: #888890;
}
.work_contact_inner
  .rewuest_contact
  .form-group
  input::-webkit-input-placeholder {
  color: #888890;
}
.work_contact_inner .rewuest_contact .form-group label {
  color: #888890;
  height: auto;
}
.work_contact_inner
  .rewuest_contact
  .form-group
  .bootstrap-select
  .dropdown-toggle {
  border-color: #ececec;
  color: #888890;
}
.work_contact_inner .rewuest_contact .form-group:last-child {
  margin-bottom: 0px;
}
.office_details_area {
  overflow: hidden;
  background: #f8f8f8;
}
.office_details_area.office_rotat .office_details_left {
  width: calc(50% - 302px);
  float: left;
  overflow: hidden;
}
.office_details_area.office_rotat .office_details_right {
  width: calc(50% + 302px);
  float: right;
  overflow: hidden;
}
.office_details_left {
  width: calc(50% + 302px);
  float: left;
  overflow: hidden;
}
.office_details_right {
  width: calc(50% - 302px);
  float: right;
  overflow: hidden;
}
.office_details_text {
  max-width: 345px;
  margin: auto;
  padding-top: 68px;
}
.office_details_text h3 {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
  text-transform: uppercase;
  padding-bottom: 26px;
}
.office_details_text h5 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #000000;
  font-weight: 400;
  line-height: 36px;
}
.office_details_text a {
  color: #000;
  display: block;
}
.office_contact_form_area {
  padding: 100px 0px 120px 0px;
}
.office_contact_form .form-group input {
  border: none;
  border-bottom: 1px solid #dadedf;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
  height: 50px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #888890;
  font-weight: 400;
}
.office_contact_form .form-group input.placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #888890;
  font-weight: 400;
}
.office_contact_form .form-group input:-moz-placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #888890;
  font-weight: 400;
}
.office_contact_form .form-group input::-moz-placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #888890;
  font-weight: 400;
}
.office_contact_form .form-group input::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #888890;
  font-weight: 400;
}
.office_contact_form .form-group input:focus {
  border-color: #6ed2e6;
}
.office_contact_form .form-group label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #888890;
  font-weight: 400;
  padding-top: 5px;
}
.office_contact_form .form-group textarea {
  border: none;
  border-bottom: 1px solid #dadedf;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
  height: 165px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #888890;
  font-weight: 400;
  padding-top: 50px;
  resize: none;
}
.office_contact_form .form-group textarea.placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #888890;
  font-weight: 400;
}
.office_contact_form .form-group textarea:-moz-placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #888890;
  font-weight: 400;
}
.office_contact_form .form-group textarea::-moz-placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #888890;
  font-weight: 400;
}
.office_contact_form .form-group textarea::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #888890;
  font-weight: 400;
}
.office_contact_form .form-group textarea:focus {
  border-color: #6ed2e6;
}
.office_contact_form .form-group .submit_dark {
  margin: auto;
  display: block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  max-width: 480px;
  margin-top: 35px;
}
.office_map_area .mapBox {
  height: 620px;
}
.office_c_area {
  overflow: hidden;
  clear: both;
  background: #f8f8f8;
}
.left_office_text {
  width: calc(50% - 320px);
  float: left;
  overflow: hidden;
}
.right_office_slider {
  width: calc(50% + 320px);
  float: right;
  overflow: hidden;
}
.c_office_slider .item img {
  width: auto;
}
.c_office_slider .owl-nav .owl-next,
.c_office_slider .owl-nav .owl-prev {
  position: absolute;
  top: 30px;
  z-index: 5;
  height: 42px;
  width: 42px;
  color: #1a1a26;
  line-height: 42px;
  text-align: center;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  cursor: pointer;
}
.c_office_slider .owl-nav .owl-next:hover,
.c_office_slider .owl-nav .owl-prev:hover {
  background: #6ed2e6;
  color: #fff;
}
.c_office_slider .owl-nav .owl-next {
  right: 30px;
}
.c_office_slider .owl-nav .owl-prev {
  right: 72px;
}
.left_office_text .office_text_inner {
  padding-top: 160px;
}
.c_office_map .mapBox {
  min-height: 860px;
}
.c_office_text_item {
  padding-bottom: 70px;
  padding-left: 130px;
}
.c_office_text_item:last-child {
  margin-bottom: 0px;
}
.c_office_text_item h5 {
  font-size: 16px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-bottom: 20px;
}
.c_office_text_item h5:last-child {
  padding-bottom: 0px;
}
.c_office_text_item a {
  color: #6ed2e6;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  padding-bottom: 20px;
  display: block;
}
.office_m_area .left_office_text {
  float: left;
  width: calc(50% + 320px);
  overflow: hidden;
}
.office_m_area .right_office_slider {
  float: right;
  width: calc(50% - 320px);
  overflow: hidden;
}
.office_m_area .right_office_slider .office_text_inner {
  padding-top: 160px;
}
.contact_d_form {
  padding: 120px 0px;
  overflow: hidden;
}
.contact_d_details h2 {
  font-size: 36px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  padding-bottom: 30px;
}
.contact_d_details p {
  font-size: 14px;
  line-height: 24px;
  color: #888890;
  font-weight: 400;
  padding-bottom: 45px;
}
.contact_d_details .contact_d_info {
  max-width: 335px;
}
.contact_d_details .contact_d_info .media {
  margin-top: 25px;
}
.contact_d_details .contact_d_info .media .media-left {
  padding-right: 25px;
}
.contact_d_details .contact_d_info .media .media-left i {
  color: #888890;
  font-size: 16px;
}
.contact_d_details .contact_d_info .media .media-body h4 {
  font-size: 16px;
  color: #1a1a26;
  line-height: 24px;
}
.contact_d_details .contact_d_info .media:first-child {
  margin-top: 0px;
}
.contact_d_details .contact_d_info .media:last-child .media-body h4 {
  color: #6ed2e6;
}
.row.m0.contact_d_form_inner {
  border: 15px solid #f5f6f6;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 45px;
  padding-bottom: 45px;
}
.row.m0.contact_d_form_inner .office_contact_form .form-group .submit_dark {
  width: 100%;
  max-width: none;
}
.row.m0.contact_d_form_inner .office_contact_form .form-group textarea {
  padding-top: 35px;
  height: 156px;
}
.get_in_touch_area {
  /* background: url("../img/get-in-touch-bg.jpg") no-repeat scroll center center; */
  background-size: cover;
  padding-top: 250px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  padding-bottom: 250px;
}
.get_in_touch_area:before {
  content: "";
  background: rgba(26, 26, 38, 0.9);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}
.get_in_touch_area .get_in_contact .get_c_title {
  margin-bottom: 40px;
}
.get_in_touch_area .get_in_contact .rewuest_contact {
  max-width: 500px;
  margin: auto;
  overflow: hidden;
  clear: both;
  color: #888890;
}
.get_in_touch_area .get_in_contact .rewuest_contact .form-group input {
  border-color: #888890;
}
.get_in_touch_area
  .get_in_contact
  .rewuest_contact
  .form-group
  input.placeholder {
  color: #888890;
}
.get_in_touch_area
  .get_in_contact
  .rewuest_contact
  .form-group
  input:-moz-placeholder {
  color: #888890;
}
.get_in_touch_area
  .get_in_contact
  .rewuest_contact
  .form-group
  input::-moz-placeholder {
  color: #888890;
}
.get_in_touch_area
  .get_in_contact
  .rewuest_contact
  .form-group
  input::-webkit-input-placeholder {
  color: #888890;
}
.get_in_touch_area .get_in_contact .rewuest_contact .form-group input:focus {
  border-color: #6ed2e6;
}
.get_in_touch_area .get_in_contact .rewuest_contact .form-group textarea {
  height: 150px;
  border: 1px solid #888890;
  border-radius: 0px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  padding: 18px 15px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.get_in_touch_area
  .get_in_contact
  .rewuest_contact
  .form-group
  textarea.placeholder {
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.get_in_touch_area
  .get_in_contact
  .rewuest_contact
  .form-group
  textarea:-moz-placeholder {
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.get_in_touch_area
  .get_in_contact
  .rewuest_contact
  .form-group
  textarea::-moz-placeholder {
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.get_in_touch_area
  .get_in_contact
  .rewuest_contact
  .form-group
  textarea::-webkit-input-placeholder {
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.get_in_touch_area .get_in_contact .rewuest_contact .form-group textarea:focus {
  border-color: #6ed2e6;
}
.get_in_touch_area .get_in_contact .rewuest_contact .form-group .submit_blue {
  margin: auto;
  display: block;
}
.get_in_touch_area .get_in_contact .rewuest_contact .form-group:last-child {
  margin-top: 30px;
  margin-bottom: 0px;
}
.get_office_details {
  overflow: hidden;
  clear: both;
  text-align: center;
}
.get_office_details .get_text_of {
  padding: 106px 0px;
}
.get_office_details .get_text_of h2 {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  color: #1a1a26;
  text-transform: uppercase;
  padding-bottom: 30px;
}
.get_office_details .get_text_of p {
  color: #888890;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-bottom: 13px;
}
.get_office_details .get_text_of h4 {
  color: #6ed2e6;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  display: block;
  padding-bottom: 13px;
}
.get_office_details .get_text_of .get_direc {
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  text-decoration: underline;
  line-height: 26px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.get_office_details .get_text_of .get_direc:hover {
  color: #6ed2e6;
}
.get_office_details .get_left_office {
  width: 50%;
  float: left;
  overflow: hidden;
  background: #ebebeb;
}
.get_office_details .get_right_office {
  width: 50%;
  float: right;
  overflow: hidden;
}
.section_img {
  overflow: hidden;
}
.modal.modal-message .modal-dialog {
  margin: 130px auto 0px;
}
.modal.modal-message .modal-dialog .modal-content {
  text-align: center;
}
.modal.modal-message .modal-dialog .modal-content .modal-header {
  border: none;
}
.modal.modal-message .modal-dialog .modal-content .modal-header h2 {
  color: #1a1a26;
  font-family: "Roboto", serif;
  font-weight: bold;
  font-size: 50px;
  letter-spacing: 2px;
  padding-top: 90px;
}
.modal.modal-message .modal-dialog .modal-content .modal-header p {
  font-size: 16px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.34px;
  padding-top: 10px;
  padding-bottom: 100px;
}
.mchimp-errmessage,
.mchimp-sucmessage {
  text-align: center;
  font-size: 14px;
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  padding-top: 10px;
}
.gray_mailchimp .mchimp-errmessage,
.gray_mailchimp .mchimp-sucmessage {
  text-align: center;
  font-size: 14px;
  color: #888890 !important;
  font-family: "Roboto", sans-serif;
  padding-top: 10px;
}
path {
  fill: #c5c5c5;
}
#world-map-gdp {
  height: 340px;
  width: 100%;
}
.works_contact_area {
  padding: 100px 0px;
}
.works_contact_area .work_contact_map .full_sec_title {
  padding-bottom: 55px;
}
.error_area {
  /* background: url("../img/error-bg.jpg") no-repeat scroll center center; */
  background-size: cover;
  text-align: center;
  color: #fff;
  min-height: 100vh;
}
.error_inner_content {
  max-width: 830px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  margin: auto;
}
.error_inner_content h5 {
  font-size: 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  padding-bottom: 10px;
}
.error_inner_content h2 {
  font-size: 240px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}
.error_inner_content p {
  font-size: 16px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  padding-bottom: 74px;
}
.error_inner_content .p_btn {
  width: 270px;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 12px;
}
.error_inner_content .quote_btn {
  width: 270px;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  background: #fff;
  color: #1a1a26;
}
.error_inner_content .quote_btn:hover {
  background: #f07f84;
  color: #fff;
}
.portfolio_area {
  padding-top: 156px;
  padding-bottom: 100px;
}
.portfolio_area .description_text {
  padding-top: 0px;
  padding-bottom: 50px;
}
.portfolio_area .description_text h5 {
  font-style: italic;
  font-weight: 700;
  font-family: "Roboto", serif;
  padding-bottom: 10px;
}
.portfolio_area .description_text h5 span {
  font-weight: 300;
}
.port_fillter {
  text-align: center;
  border-bottom: 1px solid #e6e5e1;
  padding-bottom: 40px;
  padding-top: 44px;
  margin-bottom: 60px;
}
.port_fillter li {
  display: inline-block;
  font: 400 16px/30px "Roboto", sans-serif;
  color: #1a1a26;
  border-bottom: 1px solid transparent;
  margin: 0px 20px;
  cursor: pointer;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.port_fillter li:hover {
  color: #888890;
  border-color: #888890;
}
.port_fillter li.active {
  color: #888890;
  border-color: #888890;
}
.port-gallery {
  margin-top: -50px;
}
.port-gallery .project {
  margin-top: 50px;
}
.port-gallery .port-item {
  cursor: pointer;
}
.port-gallery .port-item:before {
  display: none;
}
.port-item {
  padding: 0px;
}
.port-item:hover .port-img:before {
  opacity: 0.8;
}
.port-item:hover .port-img a {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.port-item:hover .port_text h2 {
  color: #6ed2e6;
}
.port-img {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.port-img:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: #6ed2e6;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.port-img img {
  max-width: 100%;
}
.port-img a {
  position: absolute;
  font-size: 40px;
  color: #fff;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  z-index: 1;
}
.firefox {
  border: 12px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 83%;
  margin-top: 80px;
  margin-left: 43px;
}
.port_text {
  font-family: "Roboto", sans-serif;
  position: relative;
  padding-bottom: 7px;
  padding-top: 25px;
  z-index: 1;
}
.port_text:before {
  content: "";
  width: 30px;
  height: 1px;
  background: #6ed2e6;
  bottom: 0;
  position: absolute;
}
.port_text h2 {
  font-size: 18px;
  line-height: 28px;
  color: #1a1a26;
  text-transform: uppercase;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.port_text a {
  color: #888890;
  text-transform: capitalize;
}
.port_text a:hover {
  color: #6ed2e6;
}
.port_btn {
  display: block;
  margin: 60px auto 0px;
  background: #6ed2e6;
}
.port_btn:hover {
  background: #1e1e1f;
}
.port_masonry-g {
  margin-top: -40px;
}
.port_masonry-g .project {
  margin-top: 40px;
}
.portfolio_two_area {
  padding-bottom: 100px;
}
.por_two_item {
  padding: 0px;
  position: relative;
  overflow: hidden;
}
.por_two_item img {
  max-width: 100%;
  width: 100%;
}
.por_two_item .port_hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #6ed2e6;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  padding: 30px 30px 60px;
  -webkit-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.por_two_item .port_hover .icon {
  font-size: 33px;
  position: absolute;
  top: 30px;
  right: 30px;
  color: #fff;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.por_two_item .port_hover .port_text {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  width: 100%;
  padding-left: 20px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.por_two_item .port_hover .port_text:before {
  background: #fff;
}
.por_two_item .port_hover .port_text h2 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}
.por_two_item .port_hover .port_text a {
  color: #fff;
}
.por_two_item:hover .port_hover {
  opacity: 0.8;
}
.por_two_item:hover .port_hover .icon {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.por_two_item:hover .port_hover .port_text {
  padding-left: 0px;
}
.portfolio_grid_area {
  padding-bottom: 100px;
}
.portfolio_grid_area .por_grid_item {
  position: relative;
  z-index: 1;
  margin: 15px 0px;
  cursor: pointer;
  overflow: hidden;
}
.portfolio_grid_area .por_grid_item:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  background: #fff;
  opacity: 0;
  z-index: 0;
  -webkit-transition: all 0.5s linear 0.01s;
  -o-transition: all 0.5s linear 0.01s;
  transition: all 0.5s linear 0.01s;
}
.portfolio_grid_area .por_grid_item img {
  width: 100%;
}
.portfolio_grid_area .por_grid_item .hover_text {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 60px 60px 53px;
  opacity: 0;
  -webkit-transition: all 0.5s linear 0.01s;
  -o-transition: all 0.5s linear 0.01s;
  transition: all 0.5s linear 0.01s;
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
.portfolio_grid_area .por_grid_item .hover_text a {
  font-size: 14px;
  color: #888890;
}
.portfolio_grid_area .por_grid_item .hover_text a:hover {
  color: #6ed2e6;
}
.portfolio_grid_area .por_grid_item .hover_text h2 {
  font-size: 24px;
  color: #1a1a26;
  line-height: 30px;
  text-transform: uppercase;
  padding-bottom: 18px;
}
.portfolio_grid_area .por_grid_item .hover_text .see_btn {
  font-size: 14px;
  color: #f07f84;
  padding-top: 8px;
  display: block;
}
.portfolio_grid_area .por_grid_item .hover_text .see_btn:hover {
  color: #6ed2e6;
}
.portfolio_grid_area .por_grid_item .hover_text .see_btn i {
  padding-right: 10px;
}
.portfolio_grid_area .por_grid_item:hover:before {
  opacity: 0.9;
}
.portfolio_grid_area .por_grid_item:hover .hover_text {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}
.port_carousel_area {
  padding-top: 0px;
}
.port_carousel_area .port-carousel .owl-nav .owl-next,
.port_carousel_area .port-carousel .owl-nav .owl-prev {
  position: absolute;
  top: calc(50% - 45px);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 34px;
  color: #fff;
}
.port_carousel_area .port-carousel .owl-nav .owl-prev {
  left: 30px;
}
.port_carousel_area .port-carousel .owl-nav .owl-next {
  right: 30px;
}
.carousel-item {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.carousel-item:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background: #6ed2e6;
  opacity: 0;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  z-index: 1;
}
.carousel-item:hover:before {
  opacity: 0.8;
}
.text_two {
  position: absolute;
  padding: 0px 10px 60px 60px;
  bottom: 0;
  width: 100%;
}
.text_two:before {
  display: none;
}
.text_two h2 {
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
}
.text_two a {
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.text_two a:hover {
  color: #fff;
}
.owl-dots {
  text-align: center;
  margin-top: 60px;
}
.owl-dots .owl-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #c3c3c7;
  display: inline-block;
  margin: 0px 5px;
}
.owl-dots .owl-dot.active {
  background: #6ed2e6;
}
.port_c_two_area {
  padding: 0px;
}
.port_c_two_area .port-carousel_two .carousel-item {
  position: relative;
  cursor: pointer;
}
.port_c_two_area .port-carousel_two .carousel-item:before {
  content: "";
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
  opacity: 1;
}
.port_c_two_area .port-carousel_two .carousel-item .text_two {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  bottom: auto;
  padding: 0px;
  text-align: center;
}
.port_c_two_area .port-carousel_two .carousel-item .text_two h2 {
  color: #fff;
}
.port_c_two_area .port-carousel_two .carousel-item .text_two a {
  color: #fff;
}
.port_c_two_area .port-carousel_two .carousel-item:hover:before {
  background: #6ed2e6;
  opacity: 0.5;
}
.port_c_two_area .owl-dots {
  margin: 0px;
  position: absolute;
  bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.port_c_two_area .owl-dots .owl-dot {
  background: #fff;
  opacity: 0.5;
}
.port_c_two_area .owl-dots .owl-dot.active {
  background: #6ed2e6;
}
.portfolio_masonry_two {
  margin-top: -10px;
}
.portfolio_masonry_two .portfolio_m_item {
  padding: 10px;
}
.portfolio_title {
  text-align: center;
  padding-bottom: 97px;
}
.portfolio_title h3 {
  font-size: 36px;
  line-height: 40px;
  color: #1a1a26;
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 15px;
}
.portfolio_title a {
  font: 16px;
  line-height: 24px;
  font-family: "Roboto", serif;
  color: #888890;
  font-style: italic;
  display: inline-block;
}
.portfolio_title a + a:before {
  content: "";
  width: 1px;
  height: 12px;
  background: #888890;
  display: inline-block;
  -webkit-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  transform: rotate(25deg);
  margin: 0px 7px 0px 4px;
}
.portfolio_title.text-left {
  text-align: left;
}
.single_portfolio_area {
  padding-top: 150px;
}
.single_portfolio_area .single_port_img + .single_port_img {
  margin-top: 30px;
}
.single_portfolio_area .text_s_t {
  margin-bottom: 22px;
}
.single_portfolio_area .text_s_t h3 {
  text-transform: capitalize;
}
.single_portfolio_area .driect_link {
  padding-bottom: 20px;
  padding-top: 124px;
}
.single_portfolio_area .driect_link a {
  color: #1a1a26;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}
.single_portfolio_area .driect_link a.next,
.single_portfolio_area .driect_link a.prev {
  position: relative;
  display: inline-block;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.single_portfolio_area .driect_link a.next i,
.single_portfolio_area .driect_link a.prev i {
  position: absolute;
  top: 0;
  line-height: 20px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.single_portfolio_area .driect_link a.next:hover,
.single_portfolio_area .driect_link a.prev:hover {
  color: #888890;
}
.single_portfolio_area .driect_link a.next:hover i,
.single_portfolio_area .driect_link a.prev:hover i {
  opacity: 1;
  visibility: visible;
}
.single_portfolio_area .driect_link a.prev:hover {
  padding-left: 15px;
}
.single_portfolio_area .driect_link a.next i {
  left: auto;
  right: 0;
}
.single_portfolio_area .driect_link a.next:hover {
  padding-right: 15px;
}
.portfolio_details {
  padding-top: 60px;
}
.details_text {
  padding-right: 110px;
}
.details_text .port_text {
  padding: 0px 0px 15px;
}
.details_text .port_text h2 {
  font-size: 32px;
  line-height: 35px;
  text-transform: capitalize;
  font-weight: 500;
  padding-bottom: 7px;
}
.details_text p {
  padding-top: 25px;
}
.info_text {
  margin-bottom: 40px;
}
.info_text .info_details tr td {
  color: #1a1a26;
  font-weight: 600;
  line-height: 32px;
}
.info_text .info_details tr td a {
  color: #1a1a26;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.info_text .info_details tr td a:hover {
  color: #6ed2e6;
}
.info_text .info_details tr td + td {
  padding-left: 7px;
  font-weight: 400;
}
.info_social ul li {
  display: inline-block;
}
.info_social ul li a {
  font-size: 16px;
  color: #1a1a26;
  padding-right: 36px;
  line-height: 35px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.info_social ul li a:hover {
  color: #888890;
}
.single_portfolio_two .portfolio_details {
  padding-top: 0px;
  padding-bottom: 50px;
}
.single_portfolio_two .portfolio_slider {
  position: relative;
  margin-bottom: 60px;
}
.single_portfolio_two .portfolio_slider .owl-next,
.single_portfolio_two .portfolio_slider .owl-prev {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  color: #888890;
  line-height: 56px;
  z-index: 2;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  cursor: pointer;
}
.single_portfolio_two .portfolio_slider .owl-next:hover,
.single_portfolio_two .portfolio_slider .owl-prev:hover {
  background: #6ed2e6;
  color: #fff;
}
.single_portfolio_two .portfolio_slider .owl-prev {
  left: -27px;
}
.single_portfolio_two .portfolio_slider .owl-next {
  right: -27px;
}
.single_portfolio_three .single_item + .single_item {
  margin-top: 30px;
}
.single_portfolio_three .details_text {
  padding-right: 0px;
  margin-bottom: 44px;
}
.single_portfolio_four {
  padding-top: 100px;
}
.single_portfolio_four .portfolio_details {
  padding-top: 0px;
  padding-bottom: 50px;
}
.single_por_gallery {
  margin-top: -30px;
}
.single_por_gallery .single_post {
  padding-top: 30px;
}
.single_por_gallery .single_post img {
  max-width: 100%;
  width: 100%;
}
.breadcrumb_area {
  /* background: url("../img/single-portfolio/porfolio_baner.jpg") no-repeat scroll center 0; */
  background-size: cover;
  padding: 307px 0px 320px;
}
.breadcrumb_area .breadcrumb_text h3 {
  font-size: 36px;
  line-height: 40px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 15px;
}
.breadcrumb_area .breadcrumb_text a {
  font: 16px;
  line-height: 24px;
  font-family: "Roboto", serif;
  color: #fff;
  font-style: italic;
  display: inline-block;
}
.breadcrumb_area .breadcrumb_text a + a:before {
  content: "";
  width: 1px;
  height: 12px;
  background: #fff;
  display: inline-block;
  -webkit-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  transform: rotate(25deg);
  margin: 0px 7px 0px 4px;
}
.section_menu {
  position: fixed;
  right: 55px;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 20;
}
.section_menu li {
  margin-bottom: 15px;
}
.section_menu li a {
  color: #fff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  height: 30px;
  width: 30px;
  display: block;
  vertical-align: middle;
}
.section_menu li.active a,
.section_menu li:hover a {
  font-size: 24px;
}
.section_menu li:last-child {
  margin-bottom: 0px;
}
.section_menu.color-b.content-white li a {
  color: #fff;
}
.section_menu.color-b li a {
  color: #1a1a26;
}
.nav_full_page {
  position: fixed;
  right: 100px;
  top: calc(50% + 63px);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 20;
  color: #fff;
}
.nav_full_page.color-b.content-white {
  color: #fff;
}
.nav_full_page.color-b {
  color: #1a1a26;
}
.nav_full_page li {
  cursor: pointer;
  font-size: 24px;
  display: inline-block;
  margin-right: 8px;
}
.nav_full_page li:last-child {
  margin-right: 0px;
}
.fullpage_main_area .ms-left {
  vertical-align: middle;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.fullpage_main_area .ms-left .ms-section {
  overflow: hidden;
}
.fullpage_main_area .ms-left .ms-section .left_bg1 {
  background-repeat: no-repeat;
  background-size: cover !important;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.fullpage_main_area .ms-right .ms-section {
  overflow: hidden;
}
.fullpage_main_area .ms-right .ms-section .left_bg1 {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.agency_content.fullpage_agency_tab {
  max-width: 702px;
  margin: auto;
}
.agency_content.fullpage_agency_tab .tab_fillter {
  border: none;
}
.agency_content.fullpage_agency_tab .tab_fillter li {
  padding-left: 0px;
}
.agency_content.fullpage_agency_tab .tab_fillter li a {
  color: #fff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  padding: 0px 20px;
  border: 1px solid transparent;
  line-height: 34px;
}
.agency_content.fullpage_agency_tab .tab_fillter li a:before {
  display: none;
}
.agency_content.fullpage_agency_tab .tab_fillter li.active a {
  border-color: #fff;
}
.agency_content.fullpage_agency_tab .agency_tab .tab-pane p {
  color: #fff;
}
.agency_content.fullpage_agency_tab .agency_tab .tab-pane .tab_item {
  width: auto;
  max-width: 273px;
}
.agency_content.fullpage_agency_tab .agency_tab .tab-pane .tab_item .tab-icon {
  border-color: #fff;
}
.agency_content.fullpage_agency_tab .agency_tab .tab-pane .tab_item h2 {
  color: #fff;
}
.agency_content.fullpage_agency_tab .agency_tab .l_btn {
  width: 210px;
  margin-top: 50px;
}
.video_row {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.video_row iframe {
  width: 100%;
  height: 100%;
}
.video_row .overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}
.video_row .overlay .overlay_bg {
  /* background: url("../img/fullpage-image/image-4.jpg") no-repeat scroll center center; */
  background-size: cover;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.video_row .overlay .play_pause .fa-play {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  color: #1a1a26;
  background: #fff;
  cursor: pointer;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.video_row .overlay .play_pause .fa-pause {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #6ed2e6;
  font-size: 22px;
  display: none;
}
.video_row .overlay.playing .overlay_bg {
  opacity: 0;
}
.fullpage_partner {
  width: 100%;
}
.fullpage_partner {
  max-width: 550px;
  text-align: center;
  overflow: hidden;
  padding-left: 115px;
}
.fullpage_partner ul li {
  width: calc(100% / 3);
  float: left;
  overflow: hidden;
  margin-bottom: 60px;
}
.fullpage_partner ul li a img {
  max-width: 100%;
}
.fullpage_partner ul li:nth-last-child(-n + 3) {
  margin-bottom: 0px;
}
.ms_spilt_love {
  max-width: 712px;
}
.ms_spilt_love .sec_b_title {
  margin-bottom: 45px;
}
.fullpage_client_s {
  max-width: 640px;
  margin: auto;
  text-align: center;
}
.fullpage_client_s .carousel .carousel-inner .item .content_text {
  border: 3px solid #6ed2e6;
  padding: 60px 64px;
  color: #fff;
  font-size: 18px;
  line-height: 32px;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  font-weight: 400;
  position: relative;
}
.fullpage_client_s .carousel .carousel-inner .item .content_text:before {
  content: "";
  width: 1px;
  height: 50px;
  position: absolute;
  background: #fff;
  bottom: -25px;
  left: 50%;
  z-index: 2;
}
.fullpage_client_s .carousel .carousel-inner .item .user_client img {
  margin-top: 40px;
  margin-bottom: 18px;
}
.fullpage_client_s .carousel .carousel-inner .item .user_client h4 {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  padding-bottom: 10px;
}
.fullpage_client_s .carousel .carousel-inner .item .user_client h5 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
}
.fullpage_contact2 {
  max-width: 470px;
  margin-left: 120px;
}
.right4 {
  position: relative;
}
.fullpage_map {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}
.fullpage_map .mapBox {
  width: 100%;
  height: 100vh !important;
}
.full_page_menu {
  border: none;
}
.full_page_menu:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: -1;
  -webkit-transition: all 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  -o-transition: all 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  transition: all 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}
.full_page_menu.content-white:before {
  height: 0;
}
.full_page_menu.content-white .pull-left .logo-d {
  display: none;
}
.full_page_menu.content-white .pull-left .logo-w {
  display: inline-block;
}
.full_page_menu.content-white .pull-left .h_contact_bar,
.full_page_menu.content-white .pull-left a,
.full_page_menu.content-white .pull-left i,
.full_page_menu.content-white .pull-right .h_contact_bar,
.full_page_menu.content-white .pull-right a,
.full_page_menu.content-white .pull-right i {
  color: #fff;
}
.full_page_menu .pull-left .logo-d {
  display: inline-block;
}
.full_page_menu .pull-left .logo-w {
  display: none;
}
.full_page_menu .h_contact_bar:before {
  display: none;
}
.full_page_menu .pull-right i {
  padding-right: 0px;
  padding-left: 50px;
}
.full_page_menu .pull-left .h_contact_bar,
.full_page_menu .pull-left a,
.full_page_menu .pull-left i,
.full_page_menu .pull-right .h_contact_bar,
.full_page_menu .pull-right a,
.full_page_menu .pull-right i {
  color: #1a1a26;
}
.full_page_menu .pull-left .quote_btn,
.full_page_menu .pull-right .quote_btn {
  color: #fff;
}
#fullpage_footer {
  display: none;
}
#fullpage_footer.portfolio_f .pull-left ul li a,
#fullpage_footer.portfolio_f .pull-right ul li a {
  color: #1a1a26;
}
#fullpage_footer.content-white.portfolio_f .pull-left ul li a,
#fullpage_footer.content-white.portfolio_f .pull-right ul li a {
  color: #fff;
}
@media (min-height: 700px) {
  #fullpage_footer {
    display: block;
  }
  .portfolio_f {
    display: block;
  }
}
.pagepiling .section {
  background: #fff;
}
.section .intro {
  opacity: 0;
  -webkit-transition: opacity 1s 0.3s ease;
  -o-transition: opacity 1s 0.3s ease;
  transition: opacity 1s 0.3s ease;
}
.section :not(.section-1) .intro {
  -webkit-transform: translateY(7rem);
  -ms-transform: translateY(7rem);
  -o-transform: translateY(7rem);
  transform: translateY(7rem);
  -webkit-transition: all 0.35s 0.6s ease;
  -o-transition: all 0.35s 0.6s ease;
  transition: all 0.35s 0.6s ease;
}
.section.active .intro {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.scroll-wrap {
  position: relative;
  min-height: 100%;
}
.scroll-wrap .section-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.scrollable-content {
  white-space: nowrap;
  width: 100%;
  font-size: 0px;
}
.scrollable-content:before {
  content: "";
  display: inline-block;
  height: 100vh;
  vertical-align: middle;
}
.vertical-centred {
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  font-size: 1rem;
  padding: 164px 0px 145px;
}
.vertical-centred .f_hero_content {
  padding-left: 115px;
}
.vertical-centred .f_hero_content h3 {
  font: 700 24px/62px "Roboto", serif;
  font-style: italic;
}
.vertical-centred .f_hero_content h2 {
  font-size: 60px;
  line-height: 60px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px 0px 23px;
}
.vertical-centred .f_hero_content p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  padding-bottom: 52px;
}
.vertical-centred .f_hero_content .f_btn {
  text-transform: uppercase;
  padding: 19px 30px;
  font-weight: 500;
  border-radius: 0px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.vertical-centred .f_hero_content .f_btn:hover {
  background: #6ed2e6;
  border-color: #6ed2e6;
}
.vertical-centred.split_full {
  padding: 0px;
  height: auto;
  width: auto;
  max-width: 555px;
  margin-left: 115px;
}
.vertical-centred.split_full .intro .f_hero_content {
  padding-left: 0px;
}
.vertical-centred.split_full .intro .f_hero_content h3 {
  color: #fff;
}
.vertical-centred.split_full .intro .f_hero_content h2 {
  color: #fff;
}
.vertical-centred.split_full .intro .f_hero_content p {
  color: #fff;
}
.vertical-centred.split_full .intro .f_hero_content .f_btn {
  color: #fff;
  border: 1px solid #fff;
}
.vertical-centred.split_full .intro .f_hero_content .f_btn:hover {
  background: #6ed2e6;
  color: #fff;
  border-color: #6ed2e6;
}
.text-white {
  color: #fff;
}
.text-white .f_hero_content {
  color: #fff;
}
.text-white .f_hero_content .f_btn {
  color: #fff;
  border: 1px solid #fff;
}
.text-white .lt_title {
  color: #fff;
}
.text-white .latest_menu ul li a {
  color: #fff;
}
.text-white .latest_menu ul li a:hover {
  color: #888890;
}
.text-white .latest_menu ul li.active a {
  color: #888890;
}
.text-white .office_text_item h4 {
  color: #6ed2e6;
}
.text-white .office_text_item p {
  color: #fff;
}
.full_about_text .experience_area {
  margin-top: 32px;
}
.full_about_video {
  margin-top: 100px;
}
.full_about_video .happy_hover a {
  width: 60px;
  height: 60px;
  background: #fff;
  color: #1a1a26;
  font-size: 14px;
  line-height: 60px;
}
.full_about_video .happy_hover a:hover {
  color: #fff;
}
.full_page_project .latest_menu {
  padding-bottom: 0px;
  text-align: right;
}
.full_page_project .latest_menu ul li:last-child {
  margin-right: 0px;
}
.full_page_project .lt_project {
  padding-top: 35px;
}
.full_page_project .lt_project .l_p_i {
  margin-top: 0px;
  overflow: hidden;
  cursor: pointer;
}
.full_page_project .lt_project .l_p_i .l_project_item {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.full_page_project .lt_project .l_p_i .l_project_item:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: #6ed2e6;
  opacity: 0;
  z-index: 1;
  -webkit-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.full_page_project .lt_project .l_p_i .l_project_item img {
  -webkit-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.full_page_project .lt_project .l_p_i .l_project_item:hover:before {
  opacity: 0.5;
}
.full_page_project .lt_project .l_p_i .l_project_item:hover img {
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
}
.fullpage_custom_area .full_page_title {
  padding-bottom: 40px;
}
.fullpage_custom_area .customer_slider .owl-nav .owl-next,
.fullpage_custom_area .customer_slider .owl-nav .owl-prev {
  background: #f8f8f8;
}
.fullpage_custom_area .customer_slider .owl-nav .owl-next:hover,
.fullpage_custom_area .customer_slider .owl-nav .owl-prev:hover {
  background: #6ed2e6;
}
.ms-section .sec_b_title.full_page_title {
  text-align: left;
}
.ms-section .sec_b_title.full_page_title h2:before {
  background: #fff;
}
.ms_spilt_love .sec_b_title.full_page_title.title_white {
  padding-left: 120px;
  padding-right: 15px;
}
.fullpage_contact .rewuest_contact {
  max-width: 470px;
  margin-top: 50px;
}
.fullpage_contact .rewuest_contact .submit_dark {
  background: #6ed2e6;
}
.fullpage_contact .rewuest_contact .submit_dark:hover {
  background: #1e1e1f;
  border-color: #1e1e1f;
}
.fullpage_contact .office_text_inner {
  margin-top: 128px;
}
.footer_box {
  margin-bottom: 60px;
}
.footer_box .ro_footer_area .footer_top {
  padding-left: 30px;
  padding-right: 30px;
}
.footer_box .ro_footer_area .footer_bottom {
  padding-left: 30px;
  padding-right: 30px;
}
.footer_main_area {
  padding: 118px 0px 95px 0px;
}
.footer_main_area .main_title {
  padding-bottom: 45px;
}
.footer_main_area .subscribe_form {
  max-width: 830px;
  margin: auto;
  position: relative;
}
.footer_main_area .subscribe_form input {
  border: none;
  border-bottom: 1px solid #e6e5e1;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border-radius: 0px;
  padding: 0px;
  height: 50px;
}
.footer_main_area .subscribe_form input.placeholder {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #888890;
  font-weight: 400;
}
.footer_main_area .subscribe_form input:-moz-placeholder {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #888890;
  font-weight: 400;
}
.footer_main_area .subscribe_form input::-moz-placeholder {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #888890;
  font-weight: 400;
}
.footer_main_area .subscribe_form input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #888890;
  font-weight: 400;
}
.footer_main_area .subscribe_form input:focus {
  border-color: #6ed2e6;
}
.footer_main_area .subscribe_form .btn-default {
  position: absolute;
  right: 0px;
  top: 55%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 18px;
  color: #1a1a26;
  padding: 0px;
  border: none;
  border-radius: 0px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  z-index: 5;
}
.footer_main_area .f_menu {
  overflow: hidden;
  clear: both;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 25px;
}
.footer_main_area .f_menu .navbar-nav {
  float: none;
  text-align: center;
  display: inline-block;
}
.footer_main_area .f_menu .navbar-nav li {
  margin-right: 35px;
}
.footer_main_area .f_menu .navbar-nav li a {
  background: transparent;
  padding: 0px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.footer_main_area .f_menu .navbar-nav li:last-child {
  margin-right: 0px;
}
.footer_main_area .f_menu .navbar-nav li:hover a {
  color: #1a1a26;
}
.f_social {
  text-align: center;
}
.f_social li {
  display: inline-block;
  margin-right: 34px;
}
.f_social li a {
  font-size: 18px;
  color: #888890;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.f_social li:last-child {
  margin-right: 0px;
}
.f_social li:hover a {
  color: #1a1a26;
}
.footer_copyright {
  border-top: 1px solid #e6e5e1;
  padding-top: 50px;
  padding-bottom: 50px;
}
.footer_copyright .pull-left h5 {
  font-size: 14px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.footer_copyright .pull-left h5 a {
  font-weight: 600;
  color: #1a1a26;
}
.footer_copyright .pull-left h5 a:hover {
  color: #6ed2e6;
}
.footer_copyright .pull-right h5 {
  font-size: 14px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.footer_copyright .pull-right h5 a {
  font-weight: 600;
  color: #1a1a26;
}
.footer_copyright .pull-right h5 a:hover {
  color: #6ed2e6;
}
.footer_copyright.dark_footer {
  background: #000;
  border-top: none;
  padding: 19px 0px;
}
.footer_copyright.dark_footer .pull-left h5 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #888890;
  font-weight: 400;
}
.footer_copyright.dark_footer .pull-left h5 i {
  color: #de2f36;
}
.footer_copyright.dark_footer .pull-left h5 a {
  color: #fff;
  font-weight: 400;
}
.footer_copyright.dark_footer .pull-left h5 a:hover {
  color: #6ed2e6;
}
.footer_copyright.dark_footer .pull-right h5 {
  color: "Roboto", sans-serif;
  font-weight: 400;
}
.footer_copyright.dark_footer .pull-right h5 a {
  color: #fff;
  font-weight: 400;
}
.footer_copyright.dark_footer .pull-right h5 a:hover {
  color: #6ed2e6;
}
.footer_copy {
  font-size: 14px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.footer_copy a {
  font-weight: 600;
  color: #1a1a26;
  -webkit-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.footer_copy a:hover {
  color: #6ed2e6;
}
.footer_ask {
  font-size: 14px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.footer_ask a {
  font-weight: 600;
  color: #1a1a26;
  -webkit-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.footer_ask a:hover {
  color: #6ed2e6;
}
.ro_footer_area {
  background: #1a1a26;
}
.ro_footer_area a {
  color: #fff;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.ro_footer_area a:hover {
  color: #6ed2e6;
}
.footer_top {
  padding: 5px 45px 0px;
  border-bottom: 1px solid #262636;
}
.footer_top .footer_menu li {
  display: inline-block;
}
.footer_top .footer_menu li a {
  font: 400 14px/80px "Roboto", sans-serif;
}
.footer_top .footer_menu li + li {
  padding-left: 40px;
}
.footer_top .f_content {
  text-align: right;
}
.footer_top .f_content p {
  font: 400 14px/80px "Roboto", sans-serif;
  color: #888890;
}
.footer_bottom {
  padding: 47px 45px;
}
.footer_bottom .copy_right_text {
  font: 400 14px/60px "Roboto", sans-serif;
  color: #888890;
}
.footer_bottom .footer_logo {
  text-align: center;
}
.footer_bottom .footer_logo a {
  display: inline-block;
  line-height: 60px;
}
.footer_bottom .footer_social {
  text-align: right;
}
.footer_bottom .footer_social li {
  display: inline-block;
}
.footer_bottom .footer_social li a {
  font-size: 18px;
  line-height: 60px;
  color: #888890;
}
.footer_bottom .footer_social li a:hover {
  color: #6ed2e6;
}
.footer_bottom .footer_social li + li {
  margin-left: 36px;
}
.dark_footer_area .d_footer_top_area {
  background: #353030;
}
.dark_footer_area .d_footer_top_area .d_footer_details {
  border-bottom: 1px solid #272738;
  padding: 60px 0px;
}
.d_title {
  padding-bottom: 15px;
}
.d_title h3 {
  color: #fff;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}
.d_f_detail_item p {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.footer_widget_area {
  padding: 100px 0px 120px 0px;
}
.f_title {
  padding-bottom: 40px;
}
.f_title h3 {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 400;
  position: relative;
  padding-bottom: 20px;
}
.f_title h3:before {
  content: "";
  width: 40px;
  height: 2px;
  background: #6ed2e6;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.service_w ul li a {
  font-size: 14px;
  color: #888890;
  font-family: "Roboto", sans-serif;
  line-height: 28px;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.service_w ul li:hover a {
  color: #6ed2e6;
}
.newsletter_w .input-group {
  width: 100%;
}
.newsletter_w .input-group input {
  width: 100%;
  display: block;
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid #fff;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  padding: 0px 0px;
  height: 50px;
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.newsletter_w .input-group input.placeholder {
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.newsletter_w .input-group input:-moz-placeholder {
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.newsletter_w .input-group input::-moz-placeholder {
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.newsletter_w .input-group input::-webkit-input-placeholder {
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.newsletter_w .input-group input:focus {
  border-bottom: 1px solid #6ed2e6;
}
.newsletter_w .input-group .btn-default {
  position: absolute;
  right: 0px;
  top: 55%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: transparent;
  border-radius: 0px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  color: #fff;
  padding: 0px;
  font-size: 20px;
  z-index: 5;
  cursor: pointer;
}
.newsletter_w .f_social {
  text-align: left;
  margin-top: 35px;
}
.newsletter_w .f_social li a {
  font-size: 24px;
  color: #fff;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.newsletter_w .f_social li:hover a {
  color: #888890;
}
.newsletter_w .mchimp-errmessage,
.newsletter_w .mchimp-sucmessage {
  color: #fff;
}
.f_social_list {
  text-align: left;
  margin-top: 35px;
}
.f_social_list li {
  display: inline-block;
}
.f_social_list li a {
  font-size: 24px;
  color: #fff;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
}
.f_social_list li:hover a {
  color: #888890;
}
.f_social_list li + li {
  margin-left: 36px;
}
.about_w p {
  color: #888890;
  padding-top: 2px;
}
.about_w .address_link {
  padding-top: 40px;
  padding-right: 180px;
}
.about_w .address_link li {
  font: 400 14px/24px "Roboto", sans-serif;
  color: #888890;
  padding-bottom: 8px;
}
.about_w .address_link li span {
  color: #fff;
  font-weight: 500;
}
.about_w .address_link li a {
  color: #888890;
}
.about_w .f_social {
  text-align: left;
  margin-top: 35px;
}
.about_w .f_social li a {
  font-size: 24px;
}
.instagram_w .instagram_list {
  margin-right: -9px;
  overflow: hidden;
  margin-top: 5px;
}
.instagram_w .instagram_list li {
  width: calc(100% / 3);
  float: left;
  overflow: hidden;
  padding-bottom: 9px;
}
.instagram_w .instagram_list li a {
  padding-right: 9px;
  display: block;
}
.instagram_w .instagram_list li a img {
  max-width: 100%;
}
.instagram_w .newsletter_w {
  margin-top: 43px;
}
.instagram_w .newsletter_w .input-group input {
  color: #888890;
  border-color: #888890;
}
.instagram_w .newsletter_w .input-group input.placeholder {
  color: #888890;
}
.instagram_w .newsletter_w .input-group input:-moz-placeholder {
  color: #888890;
}
.instagram_w .newsletter_w .input-group input::-moz-placeholder {
  color: #888890;
}
.instagram_w .newsletter_w .input-group input::-webkit-input-placeholder {
  color: #888890;
}
.instagram_w .newsletter_w .input-group .btn-default {
  color: #888890;
}
.instagram_w .newsletter_w .mailchimp .mchimp-errmessage,
.instagram_w .newsletter_w .mailchimp .mchimp-sucmessage {
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  padding-top: 10px;
}
.dark_f_two_area .d_footer_top_area {
  padding: 100px 0px;
}
.footer_area_five .footer_top {
  padding: 100px 0px;
  border: 0px;
}
.footer_area_five .service_w .f_title h3 {
  color: #1a1a26;
}
.footer_area_five .service_w ul li a {
  font-weight: 400;
}
.footer_area_five .service_w ul li a i {
  padding-right: 10px;
}
.footer_area_five .footer_copyright {
  border-top: 1px solid #f1f2eb;
  padding-top: 37px;
  padding-bottom: 38px;
}
.footer_area_five .footer_copyright h5 {
  color: #888890;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.footer_area_five .footer_copyright h5 a {
  font-weight: 600;
  color: #888890;
}
.footer_area_five .footer_copyright h5 a:hover {
  color: #6ed2e6;
}
.footer_area_five .footer_copyright h5 span {
  font-weight: 500;
}
.subscribe_w p {
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  color: #888890;
}
.subscribe_w .subscribe-form {
  padding-top: 27px;
}
.subscribe_w .subscribe-form .subscribe-input {
  font-size: 14px;
  line-height: 24px;
  color: #888890;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0px;
  border: 1px solid #ececec;
  height: 40px;
  padding-left: 15px;
}
.subscribe_w .subscribe-form .subscribe-input.placeholder {
  color: #888890;
}
.subscribe_w .subscribe-form .subscribe-input:-moz-placeholder {
  color: #888890;
}
.subscribe_w .subscribe-form .subscribe-input::-moz-placeholder {
  color: #888890;
}
.subscribe_w .subscribe-form .subscribe-input::-webkit-input-placeholder {
  color: #888890;
}
.subscribe_w .subscribe-form .subscribe-input:focus {
  border-color: #6ed2e6;
}
.subscribe_w .subscribe-form .subscribe-submit {
  width: 100%;
  font-size: 16px;
  line-height: 40px;
  padding: 0px;
  border: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #6ed2e6;
  text-transform: uppercase;
  color: #fff;
  margin-top: 12px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  outline: none !important;
  box-shadow: none !important;
}
.subscribe_w .subscribe-form .subscribe-submit:hover {
  background: #1a1a26;
}
.w_footer_two {
  background: #fff;
  border-top: 1px solid #dadedf;
}
.w_footer_two .about_w .address_link li span {
  color: #888890;
}
.w_footer_two .f_title h3 {
  color: #1a1a26;
}
.w_footer_two .f_social_list li a {
  color: #1a1a26;
}
.w_footer_two .f_social_list li a:hover {
  color: #888890;
}
.w_footer_two .footer_copyright {
  background: #f8f8f8;
}
.red_icon {
  color: #de2f36;
}
.footer-five {
  padding: 100px 0px;
}
.footer-five .footer_copy {
  padding-top: 28px;
  line-height: 24px;
}
.footer-five .footer_ask {
  padding-top: 25px;
  line-height: 24px;
}
@media (min-width: 992px) {
  .footer-five .footer_ask br {
    display: none;
  }
}
.footer-five .footer_social li {
  display: inline-block;
}
.footer-five .footer_social li a {
  font-size: 24px;
  color: #1a1a26;
  padding: 0px 17px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.footer-five .footer_social li a:hover {
  color: #888890;
}
.footer-five .middle_footer {
  text-align: center;
}
.footer-five .middle_footer .top_p_footer {
  max-width: 500px;
  margin: 0 auto 10px;
  position: relative;
  z-index: 1;
}
.footer-five .middle_footer .top_p_footer:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: url("../img/portfolio/portfolio-5/wave.png") no-repeat scroll center bottom; */
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}
.footer-five .middle_footer .top_p_footer .footer_social li a {
  font-size: 16px;
}
.footer-five .middle_footer .top_p_footer p {
  padding: 18px 0px 28px;
}
.footer-five .middle_footer .footer_copy {
  padding-top: 23px;
}
.footer-five .footer_copyright i {
  color: #de2f36;
}
.w_footer {
  padding: 120px 60px 37px;
  margin-bottom: 0px;
  background: #fff;
}
.w_footer .footer_top {
  border-color: #dce0e1;
}
.w_footer .footer_bottom,
.w_footer .footer_top {
  padding-left: 0px;
  padding-right: 0px;
}
.w_footer .footer_bottom a,
.w_footer .footer_top a {
  color: #1a1a26;
  font-weight: 600;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}
.w_footer .footer_top .footer_menu li a {
  color: #1a1a26;
}
.w_footer .footer_top .footer_menu li a:hover {
  color: #6ed2e6;
}
.w_footer .footer_bottom a {
  font-weight: 600;
}
.w_footer .footer_bottom a:hover {
  color: #6ed2e6;
}
.footer_dark {
  background: #252525;
}
.footer_dark .footer_social li a svg path {
  color: #fff !important;
}
.footer_dark .footer_copy a {
  color: #fff;
}
.footer_dark .footer_copy a:hover {
  color: #6ed2e6;
}
.f_border_none {
  border: 0px;
}

.panel-collapse {
  display: none;
}

.panel-collapse.open {
  display: block;
}

ul.f_social > li > a > svg > path {
  transition: fill 0.5s ease-in;
}
ul.f_social > li > a > svg > path {
  fill: #fff;
}
ul.f_social > li > a:hover > svg > path {
  fill: #888890;
}

#business_slider {
  position: relative;
}
