/* Slider Container */
.slider-container {
  position: relative;
  width: 600px;
  height: 400px;
  object-fit: cover; /* Bu özellik, resmin boyutunu sabit tutarken orijinal oranını korumak için kullanılır. */
  overflow: hidden;
}

/* Slider */
.slider {
  display: flex;
  /* Adjust transition timing function and duration */
  transition: transform 2s;
  position: relative;
}

/* Slide */
.slide {
  flex: 0 0 100%; /* Each slide occupies full width */
  /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 2s;
  background-color: #f0f0f0; /* Set your slide background color */
  /* filter: blur(5px);
  transition: filter 0.8s ease-out; */
}

.slide > div {
  background-position: left;
  background-size: 150% 100%;
  transition: background-size 2s;
}

.slide > div .slider-effect {
  position: absolute;
  width: 100%;
  height: 100%;
}

.slide > div .slider-effect > div {
  height: 100%;
  width: 10%;
  float: left;
}

.slide img {
  object-fit: fit;
  width: 100%;
  height: 100%;
}

/* Active Slide */
.slide.active {
  background-color: #ccc; /* Set your active slide background color */
  /* filter: blur(0px); */
}
.slide.active > div {
  background-position: left;
  background-size: 100% 100%;
}

/* Arrows */
.arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 5;
  height: 52px;
  width: 52px;
  background: rgba(26, 26, 38, 0.5);
  color: #fff;
  line-height: 32px;
  text-align: center;
  -webkit-transition: all 400ms linear 0s;
  -o-transition: all 400ms linear 0s;
  transition: all 400ms linear 0s;
  cursor: pointer;
  border: none;
  border-radius: 50%;
}

.arrow:hover {
  background: #6ed2e6;
}

.left-arrow {
  left: 20px;
}

.right-arrow {
  right: 20px;
}

.carousel-text {
  position: relative;
  left: 50%;
  top: 40%;
  transform: translate(-50%);
  text-align: center;
  width: fit-content;
  overflow: hidden;
}

.carousel-text h1 {
  font-size: 52px;
  margin-bottom: 20px;
  color: #fff;
  transition: all 1.5s;
  transform: translateX(100%);
}

.carousel-text p {
  font-size: 20px;
  color: #fff;
  transition: all 1.5s;
  transform: translateY(200px);
}

.carousel-text a {
  margin-top: 30px;
  transition: all 1.5s;
  transform: translateX(-100%);
}

.slider-text-desc {
  width: fit-content;
  margin: 0 auto;
  overflow: hidden;
}

.slider-button {
  width: fit-content;
  margin: 0 auto;
}

.active .carousel-text h1 {
  transform: translateX(0%);
}
.active .carousel-text p {
  transform: translateY(0);
}
.active .carousel-text a {
  transform: translateX(0);
}
